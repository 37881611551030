import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";

import { getPath } from "app/Router/RouterHelper";
import { H, TagVariant } from "common/components/atoms";
import Skeleton from "common/components/atoms/skeleton/skeleton";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import AroFooter from "./aro-footer/aro-footer";
import AroStepsContainer from "./aro-steps/aro-steps-container";
import { useAroForm } from "./use-aro-form";

const t = createTranslation(TranslationNS.pages, "aro.main");

export const StatusTagVariantMapper: Record<number, TagVariant> = {
  0: "closed", // NotFilledOut
  1: "closed", // MissingInformation
  2: "complete", // PreFilled
  3: "complete", // FilledOut
} as const;

const ReportPage = () => {
  const navigate = useNavigate();
  const { companyId } = useParams<{ companyId: string }>();

  const isAroDetailsLoading = useStoreState((store) => store.aroModel.isAroDetailsLoading);
  const year = useStoreState((store) => store.aroModel.aroDetails?.year);
  const getAroDetailsThunk = useStoreActions((store) => store.aroModel.getAroDetailsThunk);

  const { initialValues, validationSchema } = useAroForm();

  //  TODO: update submit handler with API
  const submitHandler = () => {
    navigate(getPath(["aro", "pricing"], { companyId }));
  };

  useEffect(() => {
    if (companyId) {
      getAroDetailsThunk(companyId);
    }
  }, [companyId, getAroDetailsThunk]);

  return (
    <div className="d-flex flex-column align-items-center mx-auto w-75">
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submitHandler}>
        <>
          <H.l>
            {t.el("title", {
              components: [<Skeleton key={0} isLoading={isAroDetailsLoading} />],
              values: { year: year || 2000 },
            })}
          </H.l>
          <AroStepsContainer />
          <AroFooter className="ms-17 mt-2" />
        </>
      </Formik>
    </div>
  );
};

export default ReportPage;
