import { FC, PropsWithChildren } from "react";
import cn from "classnames";

import classes from "./styles.module.scss";

type PT = PropsWithChildren<{
  isLoading: boolean;
  className?: string;
}>;

const Skeleton: FC<PT> = ({ className, children, isLoading }) => {
  if (isLoading) {
    return <span className={cn(className, classes.placeholderWave)}>{children}</span>;
  }

  return <>{children}</>;
};
export default Skeleton;
