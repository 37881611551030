import { FC, memo, useMemo, useState } from "react";

import CTable from "common/components/atoms/Table/Table";
import { sortingParams } from "common/types/Collapsible.types";
import { createTranslation, TranslationNS } from "translation";

import { Transaction } from "../types";
import classes from "./styles.module.scss";
import TransactionsTableBody from "./transactions-table-body";
import TransactionsTableHeader from "./transactions-table-header";
import { HeaderRow, TransactionsTableKeys, TransactionsTableSorting } from "./types";

type PT = {
  isLoading: boolean;
  transactions?: Transaction[];
};

const t = createTranslation(TranslationNS.pages, "aro.main.steps.transactions.body.transactionsTable.tableColumns");

const TransactionsTable: FC<PT> = memo(({ isLoading, transactions = [] }) => {
  const [activeSortedValue, setActiveSortedValue] = useState<TransactionsTableSorting>({
    field: TransactionsTableKeys.date,
    sortDirection: sortingParams.desc,
  });

  const rows: HeaderRow[] = useMemo(
    () => [
      {
        key: TransactionsTableKeys.date,
        value: t("date"),
        sortDisabled: true,
      },
      {
        key: TransactionsTableKeys.category,
        value: t("category"),
        sortDisabled: true,
      },
      {
        key: TransactionsTableKeys.type,
        value: t("type"),
        sortDisabled: true,
      },
      {
        key: TransactionsTableKeys.numberOfShares,
        value: t("numberOfShares"),
        sortDisabled: true,
      },
      {
        key: TransactionsTableKeys.price,
        value: t("price"),
      },
    ],
    []
  );

  return (
    <CTable
      className={classes.table}
      headComponent={<TransactionsTableHeader rows={rows} sort={activeSortedValue} setSort={setActiveSortedValue} />}
      bodyComponent={<TransactionsTableBody transactions={transactions} sort={activeSortedValue} />}
    />
  );
});

export default TransactionsTable;
