import { FC } from "react";
import cn from "classnames";
import { useFormikContext } from "formik";

import { Button } from "common/components/atoms";
import { createTranslation, TranslationNS } from "translation";

import { AroFormValues } from "../types";

const t = createTranslation(TranslationNS.pages, "aro.main.footer");

type PT = {
  className?: string;
};
const AroFooter: FC<PT> = ({ className }) => {
  const { submitForm, isSubmitting, isValid } = useFormikContext<AroFormValues>();

  return (
    <div className={cn("d-flex w-100 gap-3", className)}>
      <Button isDisabled={!isValid} isLoading={isSubmitting} onClick={submitForm}>
        {t("submit")}
      </Button>
      {/* TODO what on cancel ? */}
      <Button variant="secondary" isDisabled={isSubmitting} onClick={() => {}}>
        {t("cancel")}
      </Button>
    </div>
  );
};

export default AroFooter;
