import { FC } from "react";
import { useParams } from "react-router-dom";

import EditPlans from "./EditPlans/EditPlans";
import GrantPlan from "./GrantPlan/GrantPlan";
import PlanOverview from "./PlanOverview/PlanOverview";
import PlanTermination from "./PlanTermination/PlanTermination";
import RemovePlan from "./RemovePlan/RemovePlan";
import SendInvite from "./SendInvite/SendInvite";

const ManagePlanActions: FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  return (
    <>
      <PlanOverview />
      <EditPlans />
      <RemovePlan companyId={companyId ? companyId : ""} />
      <SendInvite companyId={companyId ? companyId : ""} />
      <GrantPlan companyId={companyId ? companyId : ""} />
      <PlanTermination />
    </>
  );
};

export default ManagePlanActions;
