import { FC } from "react";
import cn from "classnames";

import { Ui } from "common/components/atoms";
import { FilledArrowDownIcon } from "common/icons/svg";
import { sortingParams } from "common/types/Collapsible.types";

import { HeaderRow, ShareholdersTableKeys, ShareholdersTableSorting } from "./types";

type PT = {
  rows: HeaderRow[];
  sort: ShareholdersTableSorting;
  setSort(sort: ShareholdersTableSorting): void;
};

const ShareholdersTableHeader: FC<PT> = ({ rows, sort, setSort }) => {
  return (
    <thead>
      <tr>
        {rows.map((el) => (
          <th
            key={el.key}
            className={cn({
              ["cursor-pointer"]: !el.sortDisabled,
            })}
            onClick={() => {
              if (el.sortDisabled) {
                return;
              }

              setSort({
                field: el.key,
                sortDirection:
                  sort.field !== el.key
                    ? sortingParams.inc
                    : sort.sortDirection === sortingParams.inc
                    ? sortingParams.desc
                    : sortingParams.inc,
              });
            }}
          >
            <div
              className={cn("d-flex", {
                ["justify-content-end"]: el.key === ShareholdersTableKeys.numberOfShares,
                ["justify-content-center"]: el.key === ShareholdersTableKeys.missingInfo,
              })}
            >
              <Ui.xs>{el.value}</Ui.xs>

              {!el?.sortDisabled && (
                <span
                  className="ms-1"
                  // className={cn(classes.sort, {
                  //   [classes.active]: sortedValue.field === el.key,
                  // })}
                >
                  <FilledArrowDownIcon
                    style={{
                      transition: "all 0.2s",
                      transform:
                        sort.field === el.key && sort.sortDirection === sortingParams.inc
                          ? "rotate(0deg)"
                          : "rotate(180deg)",
                    }}
                  />
                </span>
              )}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default ShareholdersTableHeader;
