import { action, thunk } from "easy-peasy";

import { aroService } from "pages/aro/service/aro-service";

import { AroModel } from "./modelTypes/AroModel.type";

export const aroModel: AroModel = {
  // state
  aroDetails: undefined,
  isAroDetailsLoading: false,

  companyInformation: undefined,
  isCompanyInformationLoading: false,

  shareholdersAtYearStart: undefined,
  isShareholdersAtYearStartLoading: false,

  transactions: undefined,
  isTransactionsLoading: false,
  formTransactions: undefined,
  isFormTransactionsLoading: false,

  shareholdersAtYearEnd: undefined,
  isShareholdersAtYearEndLoading: false,

  dividends: undefined,
  isDividendsLoading: false,

  // actions
  setAroDetails: action((state, aroDetails) => {
    state.aroDetails = aroDetails;
  }),
  setAroDetailsLoading: action((state, isLoading) => {
    state.isAroDetailsLoading = isLoading;
  }),

  setCompanyInformation: action((state, companyInformation) => {
    state.companyInformation = companyInformation;
  }),
  setCompanyInformationLoading: action((state, isLoading) => {
    state.isCompanyInformationLoading = isLoading;
  }),

  setShareholdersAtYearStart: action((state, shareholders) => {
    state.shareholdersAtYearStart = shareholders;
  }),
  setShareholdersAtYearStartLoading: action((state, isLoading) => {
    state.isShareholdersAtYearStartLoading = isLoading;
  }),

  setTransactions: action((state, transactions) => {
    state.transactions = transactions;
  }),
  setTransactionsLoading: action((state, isLoading) => {
    state.isTransactionsLoading = isLoading;
  }),
  setFormTransactions: action((state, transactions) => {
    state.formTransactions = transactions;
  }),
  setFormTransactionsLoading: action((state, isLoading) => {
    state.isFormTransactionsLoading = isLoading;
  }),

  setShareholdersAtYearEnd: action((state, shareholders) => {
    state.shareholdersAtYearEnd = shareholders;
  }),
  setShareholdersAtYearEndLoading: action((state, isLoading) => {
    state.isShareholdersAtYearEndLoading = isLoading;
  }),

  setDividends: action((state, dividends) => {
    state.dividends = dividends;
  }),
  setDividendsLoading: action((state, isLoading) => {
    state.isDividendsLoading = isLoading;
  }),

  // thunks
  getAroDetailsThunk: thunk(async (_actions, companyId) => {
    _actions.setAroDetailsLoading(true);

    const result = await aroService.getAroDetails(companyId);
    if (result) {
      _actions.setAroDetails(result);
    }

    _actions.setAroDetailsLoading(false);
  }),

  getCompanyInformationThunk: thunk(async (_actions, companyId) => {
    _actions.setCompanyInformationLoading(true);

    const result = await aroService.getCompanyInformation(companyId);
    if (result) {
      _actions.setCompanyInformation(result);
    }

    _actions.setCompanyInformationLoading(false);
  }),

  getShareholdersAtYearStartThunk: thunk(async (_actions, companyId) => {
    _actions.setShareholdersAtYearStartLoading(true);

    const result = await aroService.getShareholdersAtYearStart(companyId);
    if (result) {
      _actions.setShareholdersAtYearStart(result);
    }

    _actions.setShareholdersAtYearStartLoading(false);
  }),

  getTransactionsThunk: thunk(async (_actions, companyId) => {
    _actions.setTransactionsLoading(true);

    const result = await aroService.getTransactions(companyId);
    if (result) {
      _actions.setTransactions(result);
    }

    _actions.setTransactionsLoading(false);
  }),
  getFormTransactionsThunk: thunk(async (_actions, companyId) => {
    _actions.setFormTransactionsLoading(true);

    const result = await aroService.getFormTransactions(companyId);
    if (result) {
      _actions.setFormTransactions(result);
    }

    _actions.setFormTransactionsLoading(false);
  }),

  getShareholdersAtYearEndThunk: thunk(async (_actions, companyId) => {
    _actions.setShareholdersAtYearEndLoading(true);

    const result = await aroService.getShareholdersAtYearEnd(companyId);
    if (result) {
      _actions.setShareholdersAtYearEnd(result);
    }

    _actions.setShareholdersAtYearEndLoading(false);
  }),

  getDividendsThunk: thunk(async (_actions, companyId) => {
    _actions.setDividendsLoading(true);

    const result = await aroService.getDividends(companyId);
    if (result) {
      _actions.setDividends(result);
    }

    _actions.setDividendsLoading(false);
  }),

  postCompanyInformationThunk: thunk(async (_actions, { companyId, data }) => {
    const result = await aroService.postCompanyInformation({ companyId, ...data });

    return result;
  }),

  postTransactionsThunk: thunk(async (_actions, data) => {
    const result = await aroService.postTransactions(data);

    return result;
  }),
};
