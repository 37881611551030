import { FC, ReactNode } from "react";
import Modal, { ModalProps } from "react-bootstrap/Modal";
import cn from "classnames";

import { H } from "../Typography";
import classes from "./ModalInfo.module.scss";

interface IModalInfo extends ModalProps {
  show: boolean;
  handleClose?: () => void;
  className?: string | undefined;
  dialogClassName?: string;
  footer?: ReactNode;
  header?: ReactNode;
}

const ModalInfo: FC<IModalInfo> = ({
  show,
  handleClose,
  className,
  header,
  footer,
  dialogClassName,
  children,
  ...rest
}) => {
  return (
    <Modal
      {...rest}
      centered
      className={cn(classes.modalInfo, className)}
      show={show}
      onHide={handleClose}
      dialogClassName={dialogClassName}
    >
      {header && (
        <Modal.Header>
          <Modal.Title>
            <H.xs>{header}</H.xs>
          </Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>{children}</Modal.Body>
      {footer && <Modal.Footer>{footer}</Modal.Footer>}
    </Modal>
  );
};

export default ModalInfo;
