import { memo, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import cn from "classnames";

import { Button, P, ToggleSwitch, Ui } from "common/components/atoms";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { aroService } from "../../../../service/aro-service";
import EditTransactionsTable from "./edit-transactions-table/table/edit-transactions-table";
import classes from "./styles.module.scss";
import TransactionsTable from "./transactions-table/transactions-table";

const t = createTranslation(TranslationNS.pages, "aro.main.steps.transactions.body");

const TransactionsBody = memo(() => {
  const { companyId } = useParams<{ companyId: string }>();
  const [isEditTableOpen, setIsEditTableOpen] = useState(false);

  const transactions = useStoreState((state) => state.aroModel.transactions);
  const isLoading = useStoreState((state) => state.aroModel.isTransactionsLoading);
  const getTransactions = useStoreActions((store) => store.aroModel.getTransactionsThunk);
  const getAroDetails = useStoreActions((store) => store.aroModel.getAroDetailsThunk);

  useEffect(() => {
    if (companyId) {
      getTransactions(companyId);
    }
  }, [companyId, getTransactions]);

  const [isDone, setIsDone] = useState(false);

  const handleChangeTransactionsDone = useCallback(async () => {
    if (companyId) {
      const success = await aroService.postTransactionsDone({ companyId, transactionsDone: !isDone });

      if (success) {
        setIsDone(!isDone);
        getAroDetails(companyId);
      }
    }
  }, [companyId, getAroDetails, isDone]);

  const handleOpenEditTableClick = useCallback(() => {
    setIsEditTableOpen(true);
  }, []);

  const handleCloseEditTableClick = useCallback(() => {
    setIsEditTableOpen(false);
  }, []);

  return (
    <div>
      <div className={cn(classes.backgroundElement, "d-flex justify-content-between py-2 px-3 mb-2 rounded-3")}>
        <div>
          <Ui.m bold>{t("transactionsTable.title")}</Ui.m>
          <P.s>{t("transactionsTable.paragraph")}</P.s>
        </div>
        <ToggleSwitch checked={isDone} onChange={handleChangeTransactionsDone} />
      </div>
      <div className={cn(classes.backgroundElement, classes.border, "rounded-3")}>
        <div className={cn("d-flex justify-content-between py-2 px-3", classes.borderBottom)}>
          <div>
            <Ui.m bold>{t("transactionsTable.title")}</Ui.m>
            <P.s>{t("transactionsTable.paragraph")}</P.s>
          </div>
          <Button onClick={handleOpenEditTableClick}>{t("transactionsTable.editBtn")}</Button>
        </div>
        <div className="p-3">
          <TransactionsTable isLoading={isLoading} transactions={transactions?.transactions} />
        </div>
      </div>
      {isEditTableOpen && <EditTransactionsTable onClose={handleCloseEditTableClick} />}
    </div>
  );
});

export default TransactionsBody;
