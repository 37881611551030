import { FC } from "react";

import Step from "common/components/atoms/Step/Step";

import { AroSteps } from "../types";
import StepContainer from "./step-container/step-container";

const AroStepsContainer: FC = () => {
  return (
    <Step className="w-100">
      {Object.values(AroSteps).map((id) => (
        <StepContainer key={id} stepId={id} />
      ))}
    </Step>
  );
};

export default AroStepsContainer;
