import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";

import { getPath } from "app/Router/RouterHelper";
import { Button, H, P } from "common/components/atoms";
import { OnboardingCompanySearch } from "common/components/molecules";
import { ArrowLeftIcon, ArrowRightIcon, LinkIcon } from "common/icons/svg";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import chartImg from "./Chart.png";
import classes from "./GetStarted.module.scss";

const uniMicroHref = process.env.REACT_APP_UNI_MICRO_LANDING;
const uniMicroSRBankHref = process.env.REACT_APP_UNI_MICRO_SRBANK_LANDING;

const t = createTranslation(TranslationNS.pages, "onboard.company.getStarted");

const GetStarted: FC = () => {
  const navigate = useNavigate();
  const [selectedCompany, setSelectedCompany] = useState<string | undefined>(undefined);

  const isGetCompanyLoading = useStoreState((state) => state.companyOnboardingModel.isGetCompanyLoading);
  const companyType = useStoreState((state) => state.companyOnboardingModel.companyType);
  const getBreggCompanyInfoThunk = useStoreActions(
    (actions) => actions.companyOnboardingModel.getBreggCompanyInfoThunk
  );
  const setShareholders = useStoreActions((actions) => actions.companyOnboardingModel.setShareholders);
  const setIntegration = useStoreActions((actions) => actions.companyOnboardingModel.setIntegration);
  const setCompany = useStoreActions((actions) => actions.companyOnboardingModel.setCompany);

  const isSimplifyOnboard = companyType === "few-owners";

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSelectedCompany(e.target.value);
  }, []);

  const handleContinue = useCallback(() => {
    if (selectedCompany) {
      getBreggCompanyInfoThunk(selectedCompany)
        .then(() => {
          notify(t("dataImported"), true, "success", 3000);
          navigate(getPath(["onboard", "company", "companyInformation"], { companyId: undefined }));
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [getBreggCompanyInfoThunk, navigate, selectedCompany]);

  useEffect(() => {
    setShareholders([]);
    setIntegration({
      type: "all",
      data: null,
    });
    setCompany(null);
  }, [setShareholders, setCompany, setIntegration]);

  return (
    <div className={classes.wrap}>
      <Row className="p-0 m-0 h-100">
        <Col md={8} className="p-0 m-0 ps-3 py-3 pe-6 d-flex flex-column">
          <H.s>{t(isSimplifyOnboard ? "simplifyTitle" : "title")}</H.s>
          <P.m className="mt-2 mb-6" color="foregroundMedium">
            {t("description")}{" "}
          </P.m>

          <OnboardingCompanySearch
            onChange={handleChange}
            disabled={isGetCompanyLoading}
            selectedValue={selectedCompany}
          />

          {isSimplifyOnboard ? (
            <Link to={getPath(["onboard", "company", "companyInformation"], { companyId: undefined })}>
              <Button variant="tertiary" size="s" className="mt-3 w-max-content">
                {t("cantFindCompany")}
              </Button>
            </Link>
          ) : null}

          {/*<Divider className="mt-9 mb-9" />*/}

          <div className="d-flex mt-auto">
            <Button
              isOnlyIcon
              variant="secondary"
              onClick={() => {
                navigate(-1);
              }}
            >
              <ArrowLeftIcon />
            </Button>

            {isSimplifyOnboard ? null : (
              <Link
                className="ms-auto"
                to={getPath(["onboard", "company", "companyInformation"], { companyId: undefined })}
              >
                <Button variant="secondary">{t("skipImport")}</Button>
              </Link>
            )}

            <Button
              isOnlyIcon
              onClick={handleContinue}
              isDisabled={!selectedCompany || isGetCompanyLoading}
              isLoading={isGetCompanyLoading}
              className={classNames({
                "ms-3": !isSimplifyOnboard,
                "ms-auto": isSimplifyOnboard,
              })}
            >
              <ArrowRightIcon />
            </Button>
          </div>

          {isSimplifyOnboard ? null : (
            <div className="d-flex mt-5">
              <Button
                variant="tertiary"
                as="a"
                target="_self"
                rel="noreferrer"
                size="s"
                iconRight={<LinkIcon />}
                href={uniMicroSRBankHref}
              >
                {t("importFromUniMicroSRBank")}
              </Button>
              <Button
                variant="tertiary"
                as="a"
                target="_self"
                rel="noreferrer"
                size="s"
                iconRight={<LinkIcon />}
                href={uniMicroHref}
              >
                {t("importFromUniMicro")}
              </Button>
            </div>
          )}
        </Col>
        <Col className="p-0 m-0">
          <div className="bg-secondary rounded-3  h-100 w-100 d-flex align-items-center justify-content-center">
            <img src={chartImg} alt="chart" className="w-700" />
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default GetStarted;
