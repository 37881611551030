import { useAccordionButton } from "react-bootstrap";

import { P, Tag, Ui } from "common/components/atoms";
import Skeleton from "common/components/atoms/skeleton/skeleton";
import ProgrammaticAccordionToggle from "pages/equity-management/ownership/transactions/components/transactions-list/programmatic-accordion-toggle/programmatic-accordion-toggle";
import { AroSectionStatuses } from "store/modelTypes/AroModel.type";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { StatusTagVariantMapper } from "../../../main";
import { AroSteps } from "../../../types";

const t = createTranslation(TranslationNS.pages, "aro.main.steps.shareholdersAtYearStart.header");

const ShareholdersAtYearStartHeader = () => {
  const decoratedOnClick = useAccordionButton(AroSteps.ShareholdersAtYearStart);
  const previousShareholdersStatus = useStoreState(
    (store) => store.aroModel.aroDetails?.previousShareholdersStatus || 0
  );
  const year = useStoreState((store) => store.aroModel.aroDetails?.year);
  const isAroDetailsLoading = useStoreState((store) => store.aroModel.isAroDetailsLoading);

  return (
    <div onClick={decoratedOnClick} className="d-flex justify-content-between py-2 px-3">
      <div>
        <Ui.xl bold>
          {t.el("title", {
            components: [<Skeleton key={0} isLoading={isAroDetailsLoading} />],
            values: { date: `1. jan ${year || 2000}` },
          })}
        </Ui.xl>
        <P.s color="foregroundMedium">
          {t.el("subtitle", {
            components: [<Skeleton key={0} isLoading={isAroDetailsLoading} />],
            values: { date: `01.01.${year || 2000}` },
          })}
        </P.s>
      </div>
      <div className="d-flex align-items-center gap-2">
        <Tag variant={StatusTagVariantMapper[previousShareholdersStatus]}>
          <Skeleton isLoading={isAroDetailsLoading}>{AroSectionStatuses[previousShareholdersStatus]}</Skeleton>
        </Tag>
        <ProgrammaticAccordionToggle eventKey={AroSteps.ShareholdersAtYearStart} itemIdToOpen={null} />
      </div>
    </div>
  );
};

export default ShareholdersAtYearStartHeader;
