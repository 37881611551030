import { useCallback, useState } from "react";

const useFullScreenView = () => {
  const [isFullScreenOpen, setIsFullScreenOpen] = useState(false);
  const handleOpenFullScreen = useCallback(() => setIsFullScreenOpen(true), [setIsFullScreenOpen]);
  const handleCloseFullScreen = useCallback(() => setIsFullScreenOpen(false), [setIsFullScreenOpen]);

  return { isFullScreenOpen, handleOpenFullScreen, handleCloseFullScreen };
};

export default useFullScreenView;
