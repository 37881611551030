import { useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { P } from "common/components/atoms";
import Skeleton from "common/components/atoms/skeleton/skeleton";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import BodySection from "../../step-container/body-container/body-section";
import ShareholdersTable from "../shareholders-table/shareholders-table";

const t = createTranslation(TranslationNS.pages, "aro.main.steps.shareholdersAtYearStart.body");

const ShareholdersAtYearStartBody = () => {
  const { companyId } = useParams<{ companyId: string }>();

  const year = useStoreState((state) => state.aroModel.aroDetails?.year);
  const shareholders = useStoreState((state) => state.aroModel.shareholdersAtYearStart?.shareholders);
  const isLoading = useStoreState((state) => state.aroModel.isShareholdersAtYearStartLoading);

  const getShareholderAtYearStart = useStoreActions((store) => store.aroModel.getShareholdersAtYearStartThunk);

  useEffect(() => {
    if (companyId) {
      getShareholderAtYearStart(companyId);
    }
  }, [companyId, getShareholderAtYearStart]);

  return (
    <Accordion flush defaultActiveKey="start">
      <BodySection eventKey="start" hideToggle>
        <P.m className="mb-3" color="foregroundMedium">
          {t.el("listOfShareholders.paragraph", {
            components: [<Skeleton key={0} isLoading={isLoading} />],
            values: { date: year ? year - 1 : 2000 },
          })}
        </P.m>
        <ShareholdersTable type="start" isLoading={isLoading} shareholders={shareholders || []} />
      </BodySection>
    </Accordion>
  );
};

export default ShareholdersAtYearStartBody;
