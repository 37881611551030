export const AroSteps = {
  CompanyInformation: "1",
  CreateLink: "2",
  ShareholdersAtYearStart: "3",
  Transactions: "4",
  ShareholdersAtYearEnd: "5",
  Dividends: "6",
} as const;

export type AroStepsIds = (typeof AroSteps)[keyof typeof AroSteps];

export type AroFormValues = {};
