import BTable from "react-bootstrap/Table";
import { flexRender, Table } from "@tanstack/react-table";
import cn from "classnames";

import { fields } from "pages/onboard/company/import-ownership/useImportOwnershipForm";

import classes from "./ImportTable.module.scss";

type TableType = "founders";

const ImportTable = <T extends object>({
  table,
  tableType,
  className,
}: {
  table: Table<T>;
  tableType?: TableType;
  className?: string;
}) => {
  const isFoundersTable = tableType === "founders";

  return (
    <div className={classes.customScroll}>
      <BTable bordered striped className={cn(classes.wrap, className)}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const canResize = header.column.getCanResize();
                const maxWidth = canResize ? header.column.columnDef.maxSize : undefined;
                const minWidth = canResize ? header.column.columnDef.minSize : undefined;
                const width = canResize ? header.column.getSize() : undefined;

                return (
                  <th
                    className={header.column.columnDef.meta?.headClass}
                    key={header.id}
                    style={{ width, minWidth, maxWidth }}
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    <div
                      {...{
                        onDoubleClick: () => header.column.resetSize(),
                        onMouseDown: header.getResizeHandler(),
                        onTouchStart: header.getResizeHandler(),
                        className: cn(classes.resizer, {
                          [classes.isResizing]: header.column.getIsResizing(),
                        }),
                      }}
                    />
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  const canResize = cell.column.getCanResize();
                  const maxWidth = canResize ? cell.column.columnDef.maxSize : undefined;
                  const minWidth = canResize ? cell.column.columnDef.minSize : undefined;
                  const width = canResize ? cell.column.getSize() : undefined;

                  return (
                    <td key={cell.id} style={{ maxWidth, minWidth, width }}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        {table.options.meta?.footerShow && (
          <tfoot>
            {table.getFooterGroups().map((footerGroup) => {
              return (
                <tr key={footerGroup.id}>
                  {footerGroup.headers.map((header) => {
                    if (isFoundersTable && header.id !== fields.email && header.id !== fields.companyName)
                      return (
                        <th
                          key={header.id}
                          colSpan={isFoundersTable && header.id === fields.isCompanyOwned ? 3 : header.colSpan}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.footer, header.getContext())}
                        </th>
                      );
                  })}
                </tr>
              );
            })}
          </tfoot>
        )}
      </BTable>
    </div>
  );
};
export default ImportTable;
