import { ChangeEvent, useCallback, useState } from "react";
import { Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { isNil } from "ramda";

import { getPath } from "app/Router/RouterHelper";
import { Button, H, P } from "common/components/atoms";
import { OnboardingCompanySearch } from "common/components/molecules";
import { ChevronRightIcon } from "common/icons/svg";
import { useStoreActions } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import Img from "../../../onboard/company/assets/card-img-1.png";
import classes from "./styles.module.scss";

const t = createTranslation(TranslationNS.pages, "onboard.company.aroOnboarding");

const AroOnboarding = () => {
  const navigate = useNavigate();

  const setAroOnboarding = useStoreActions((actions) => actions.companyOnboardingModel.setAroOnboarding);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] = useState<string | undefined>(undefined);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSelectedCompany(e.target.value);
  }, []);

  const createCompanyHandler = useCallback(async () => {
    try {
      setIsLoading(true);
      const request = await axios.post("/api/aro/create-company", {
        organizationNumber: selectedCompany,
      });

      if (request.status === 200) {
        setAroOnboarding({
          isActive: false,
        });

        navigate(getPath(["aro", "report"], { companyId: request.data.companyId }));
      }
    } catch (e) {
      console.error({ e });
    } finally {
      setIsLoading(false);
    }
  }, [navigate, selectedCompany, setAroOnboarding]);

  return (
    <div className={classes["wrap"]}>
      <div className="px-6">
        <H.s className="mt-7">{t("title")}</H.s>

        <P.l className="mt-2 mb-6" color="foregroundMedium">
          {t("description")}
        </P.l>

        <OnboardingCompanySearch onChange={handleChange} selectedValue={selectedCompany} />

        <Button
          className="mt-3 mb-7"
          isLoading={isLoading}
          isDisabled={isLoading || isNil(selectedCompany)}
          iconRight={<ChevronRightIcon />}
          onClick={createCompanyHandler}
        >
          {t("later")}
        </Button>
      </div>

      <div className={classes["img-wrap"]}>
        <Image src={Img} />
      </div>
    </div>
  );
};

export default AroOnboarding;
