import { FC, useMemo } from "react";
import { useFormikContext } from "formik";

import Dropdown from "common/components/atoms/Dropdown/Dropdown";
import classes from "common/components/atoms/ImportTable/inputs/inputs.module.scss";
import Tooltip from "common/components/atoms/Tooltip/Tooltip";
import { WarningExclamationMarkIcon } from "common/icons/svg";

type SourceFieldProps = {
  sourceOptions: { id: number | string; name: string }[];
  rowIndex: number;
  columnId: string;
  placeholder?: string;
  isDisabled?: boolean;
};

type FormikValues = {
  [key: string]: number | undefined;
};

const SourceField: FC<SourceFieldProps> = ({ sourceOptions, rowIndex, columnId, placeholder, isDisabled }) => {
  const { values, handleChange, handleBlur, errors, touched } = useFormikContext<FormikValues[]>();

  const error = errors[rowIndex]?.[columnId];
  const value = values[rowIndex]?.[columnId];
  const touchedValue = touched?.[rowIndex]?.[columnId];

  const data = useMemo(() => {
    return { options: sourceOptions, isSearchable: false };
  }, [sourceOptions]);

  return (
    <>
      {error && touchedValue && (
        <div className={classes.errorIcon}>
          <Tooltip className={classes.errorTooltip} popupContent={error}>
            <span>
              <WarningExclamationMarkIcon />
            </span>
          </Tooltip>
        </div>
      )}
      <Dropdown
        name={`[${rowIndex}].${columnId}`}
        className={classes.selectInput}
        isSearchable={data.isSearchable}
        options={data.options}
        optionsIsObject
        selectedValue={value}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={placeholder}
        isDisabled={isDisabled}
      />
    </>
  );
};

export default SourceField;
