import { useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";

import { Ui } from "common/components/atoms";
import { TextInput } from "common/components/atoms/ImportTable";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { createTranslation, TranslationNS } from "translation";

import { ShareholderEditFormValues } from "../../types";
import { fields } from "./use-edit-shareholders-form";

const t = createTranslation(
  TranslationNS.pages,
  "aro.main.steps.shareholdersAtYearEnd.body.shareholdersEditForm.tableColumns"
);

const useEditTransactionsColumns = () => {
  const fNumber = useFormatNumbers();

  const columns = useMemo<ColumnDef<ShareholderEditFormValues>[]>(() => {
    const cells: ColumnDef<ShareholderEditFormValues>[] = [
      {
        header: t("shareholder"),
        accessorKey: fields.shareholderName,
        cell: (props) => {
          const name = props.row.getValue(fields.shareholderName) as string;

          return <Ui.s>{name}</Ui.s>;
        },
        minSize: 250,
      },
      {
        header: t("isin"),
        accessorKey: fields.isin,
        cell: (props) => {
          return <TextInput rowIndex={props.row.index} columnId={props.column.id} />;
        },
        minSize: 210,
      },
      {
        header: t("numberOfShares"),
        accessorKey: fields.numberOfShares,
        cell: (props) => {
          const numberOfShares = props.row.getValue(fields.numberOfShares) as number;

          return <Ui.s>{fNumber(numberOfShares, "amount")}</Ui.s>;
        },
        minSize: 210,
      },
    ];

    return cells;
  }, [fNumber]);

  return { columns };
};

export default useEditTransactionsColumns;
