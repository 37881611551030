import React from "react";
import { Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import { publicRouteList } from "app/Router/RouterV2.types";
import { H } from "common/components/atoms";
import { StripePricingTable } from "common/components/molecules";
import { useStoreState } from "store/store";

import classes from "./styles.module.scss";

const AroPricingTable = () => {
  const navigate = useNavigate();

  const { companyId } = useParams<{ companyId: string }>();

  const account = useStoreState((state) => state.account);

  const isAdmin = account?.user?.isSysAdmin;

  const handleAdminSkipRegistrationRequest = async () => {
    try {
      const request = await axios.post("/api/admin/add-subscription", {
        companyId,
      });

      if (request.status === 200) {
        navigate(`/${publicRouteList.subscriptionAccepted}`, {
          replace: true,
        });
      }
    } catch (e) {
      console.error({ e });
    }
  };

  return (
    <div className={classes.wrap}>
      <H.s className="mb-2">Aro pricing table</H.s>
      <StripePricingTable companyId={String(companyId)} tableID="prctbl_1QSys9BNxcVSl80zL0HHTdEK" />

      <Row className="mt-3">
        {isAdmin ? (
          <div className={classes["ugly-button"]}>
            <button onClick={handleAdminSkipRegistrationRequest}>Subscribe company as admin</button>
          </div>
        ) : null}

        {/*<Banner title={t("quantityTitle")} description={t("quantityDescription")} />*/}
      </Row>
    </div>
  );
};

export default AroPricingTable;
