import { ChangeEvent, FC, ReactNode, useCallback, useMemo, useState } from "react";
import debounce from "lodash.debounce";

import { Dropdown, Helper } from "common/components/atoms";
import { useStoreActions } from "store/store";
import { createTranslation, TranslationNS } from "translation";

const t = createTranslation(TranslationNS.pages, "onboard.company.getStarted");

const MIN_SEARCH_INPUT_LENGTH = 2;

type Props = {
  disabled?: boolean;
  selectedValue?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

const OnboardingCompanySearch: FC<Props> = ({ disabled, selectedValue, onChange }) => {
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false);
  const [listCompanies, setListCompanies] = useState<{ id: string; name: string; element: ReactNode }[]>([]);

  const searchBrregCompanyThunk = useStoreActions((actions) => actions.companyOnboardingModel.searchBrregCompanyThunk);

  const debounceChange = useMemo(
    () =>
      debounce((value: string) => {
        searchBrregCompanyThunk(value)
          .then((res) => {
            setListCompanies(
              res.data.companies.map((company) => ({
                id: company.organizationNumber || "",
                name: company.name,
                element: (
                  <div>
                    <strong>{company.name}</strong> - {company.city} - {company.organizationNumber}
                  </div>
                ),
              }))
            );
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            setIsSearchLoading(false);
          });
      }, 1000),
    [searchBrregCompanyThunk]
  );

  const handleSearchChange = useCallback(
    (value: string) => {
      if (value.length < MIN_SEARCH_INPUT_LENGTH) {
        setIsSearchLoading(false);
        setListCompanies([]);
        debounceChange.cancel();
        return;
      }

      setIsSearchLoading(true);
      debounceChange(value);
    },
    [debounceChange]
  );

  return (
    <Helper>
      <Helper.Question type="input" questionId="find company">
        <Dropdown
          label={t("search.label")}
          placeholder={t("search.placeholder")}
          searchPlaceholder={t("search.searchPlaceholder")}
          isSearchable
          optionsIsObject
          selectedValue={selectedValue}
          options={listCompanies}
          onChange={onChange}
          searchChangeCallback={handleSearchChange}
          isLoading={isSearchLoading}
          isDisabled={disabled}
          withoutToggleChevron
          showSearchIcon
          isClearable
          filterOff
          isSearchComponent
        />
      </Helper.Question>
      <Helper.Answer className="my-2" answerId="find company" text={t("helperText")} withRightMargin />
    </Helper>
  );
};

export default OnboardingCompanySearch;
