import { FC, PropsWithChildren } from "react";
import { useAccordionButton } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import cn from "classnames";

import { H, Tag } from "common/components/atoms";
import ProgrammaticAccordionToggle from "pages/equity-management/ownership/transactions/components/transactions-list/programmatic-accordion-toggle/programmatic-accordion-toggle";
import { AroSectionStatuses } from "store/modelTypes/AroModel.type";

import { StatusTagVariantMapper } from "../../../main";
import classes from "./styles.module.scss";

type PT = PropsWithChildren<{
  eventKey: string;
  title?: string;
  status?: AroSectionStatuses;
  className?: string;
  hideToggle?: boolean;
  customLeftItem?: JSX.Element;
}>;

const BodySection: FC<PT> = ({
  customLeftItem,
  eventKey,
  title,
  status = -1,
  children,
  hideToggle = false,
  className,
}) => {
  const decoratedOnClick = useAccordionButton(eventKey);

  return (
    <div className={cn(classes.elementBackground, "rounded-3 p-3 cursor-pointer", className)}>
      <div className="d-flex align-items-center" onClick={decoratedOnClick}>
        {customLeftItem}
        {title ? <H.xxs className="me-auto">{title}</H.xxs> : null}
        {status !== -1 ? (
          <Tag className="me-2" variant={StatusTagVariantMapper[status]}>
            {AroSectionStatuses[status]}
          </Tag>
        ) : null}
        {hideToggle ? null : <ProgrammaticAccordionToggle eventKey={eventKey} itemIdToOpen={null} />}
      </div>
      <Accordion.Collapse eventKey={eventKey}>
        <div className={cn({ ["border-top mt-3 pt-3"]: title })}>{children}</div>
      </Accordion.Collapse>
    </div>
  );
};

export default BodySection;
