import React, { MouseEventHandler, useCallback, useState } from "react";
import { Accordion, Col } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import classNames from "classnames";
import { Formik, FormikHelpers } from "formik";

import { Banner, Button, H, P, TextField, ToggleSwitch, Ui } from "common/components/atoms";
import { ToastFormikValidator } from "common/hooks/useToastFormikValidator";
import { PlayIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { CreateLinkFormValues } from "../company-information/types";
import BodySection from "../step-container/body-container/body-section";
import Img1 from "./assets/img_1.jpg";
import Img2 from "./assets/img_2.jpg";
import Img3 from "./assets/img_3.jpg";
import Img4 from "./assets/img_4.jpg";
import classes from "./style.module.scss";
import useCreateLinkForm from "./use-create-link-form";

const t = createTranslation(TranslationNS.pages, "aro.main.steps.createLink.body");

const CreateLinkBody = () => {
  const { companyId } = useParams<{ companyId: string }>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [verificationMobileNumber, setVerificationMobileNumber] = useState<string>("");
  const [isVerifyFieldShown, setIsVerifyFieldShown] = useState<boolean>(false);

  const companyInformation = useStoreState((state) => state.aroModel.companyInformation);
  const aroDetails = useStoreState((store) => store.aroModel.aroDetails);
  const getAroDetailsThunk = useStoreActions((store) => store.aroModel.getAroDetailsThunk);

  const { validationSchema, initialValues } = useCreateLinkForm();

  // the same submit callback will be used for both - send sms and update aro details API calls
  // depends on internal form value isVerificationSuccess - we're switching between 2 API calls
  const submit = useCallback(
    async (values: CreateLinkFormValues, actions: FormikHelpers<any>) => {
      setIsLoading(true);

      try {
        if (!values.isVerificationSuccess) {
          const request = await axios.post<{
            errorMessage: string | null;
            phoneNumber: string;
            success: boolean;
          }>("/api/aro/sms-code", {
            companyId,
            systemId: values.altinnId,
            systemPassword: values.dataSystemPassword,
            userPassword: values.loginPassword,
            userISIN: values.birthNumber,
          });

          if (request.status === 200 && request.data.success) {
            notify(`Sms code has been sent to you mobile ${request.data.phoneNumber}`, true, "success");

            setVerificationMobileNumber(request.data.phoneNumber);
            setIsVerifyFieldShown(true);
            actions.setFieldValue("isVerificationSuccess", true);
          }
        } else {
          const request = await axios.post<{
            errorMessage: string | null;
            success: boolean;
          }>("/api/aro/altinn/details", {
            companyId,
            systemId: values.altinnId,
            systemPassword: values.dataSystemPassword,
            userPassword: values.loginPassword,
            userISIN: values.birthNumber,
            organizationNumber: companyInformation?.organizationNumber,
            pinCode: values.verificationCode,
          });

          if (request.status === 200 && request.data.success) {
            notify(t("step2.successConnection"), true, "success");

            getAroDetailsThunk(companyId || "");
          }

          if (request.data.errorMessage) {
            notify(t("step2.checkVerification"), true, "error");
          }
        }
      } catch (e) {
        console.error({ e });
      } finally {
        setIsLoading(false);
      }
    },
    [companyId, companyInformation?.organizationNumber, getAroDetailsThunk]
  );

  return (
    <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={submit}>
      {({ handleSubmit, errors, touched, setFieldValue, handleChange, values }) => {
        return (
          <>
            <ToastFormikValidator />

            <div className={classNames("mb-2 d-flex justify-content-between", classes["head-container"])}>
              <Col xs={6} className="m-0 p-0">
                <H.xxs>{t("topSection.title")}</H.xxs>
                <P.s color="foregroundMedium" className="mt-2 mb-3">
                  {t("topSection.description1")}
                </P.s>

                <P.s color="foregroundMedium">{t("topSection.description2")}</P.s>
                <P.s color="foregroundMedium" className="mb-2">
                  {t("topSection.description3")}
                </P.s>

                <P.s color="foregroundMedium">{t("topSection.description4")}</P.s>
              </Col>

              <Col xs={6} className="m-0 p-0">
                <div className={classes["video"]}>
                  <div className={classes["circle"]}>
                    <PlayIcon fontSize={24} />
                  </div>
                </div>
              </Col>
            </div>

            <Accordion flush alwaysOpen defaultActiveKey={["a", "b"]}>
              <BodySection
                eventKey="a"
                title={t("step1.title")}
                status={aroDetails?.altinnConnectionStatus}
                className="mb-1_half"
                customLeftItem={
                  <div className={classNames("me-2", classes["step-circle"])}>
                    <Ui.m bold>1</Ui.m>
                  </div>
                }
              >
                <Ui.m bold color="foregroundMedium">
                  {t.el("step1.logInExplanation", {
                    components: [
                      <Link key="1" className={classes["link"]} to="https://info.altinn.no/" target="_blank" />,
                    ],
                  })}
                </Ui.m>

                <Image src={Img1} className="w-100 mt-3 mb-4 rounded-4" />

                <Ui.m bold color="foregroundMedium">
                  {t("step1.selectProfile")}
                </Ui.m>

                <Image src={Img2} className="w-100 mt-3 mb-4 rounded-4" />

                <Ui.m bold color="foregroundMedium" className="mb-3">
                  {t("step1.scrollToLoginInfo")}
                </Ui.m>

                <Ui.m color="foregroundMedium" className="mb-3">
                  {t("step1.recordLoginInfo")}
                </Ui.m>

                <Image src={Img3} className="w-100 mb-3 rounded-4" />

                <Banner variant="expired" description={t("step1.skipIfLogin")} />

                <P.s className="mt-3" color="foregroundMedium">
                  {t("step1.altinSignPoint1")}
                </P.s>
                <P.s color="foregroundMedium" className="mb-3">
                  {t("step1.altinSignPoint2")}
                </P.s>

                <Banner className="my-3" variant="expired" description={t("step1.avoidSpecialCharacters")} />

                <P.s color="foregroundMedium" className="mb-3">
                  {t("step1.rememberPassword")}
                </P.s>

                <P.s color="foregroundMedium">{t("step1.altinSignPoint3")}</P.s>
                <P.s color="foregroundMedium">{t("step1.altinSignPoint4")}</P.s>
                <P.s color="foregroundMedium">{t("step1.altinSignPoint5")}</P.s>

                <Ui.m bold color="foregroundMedium" className="mt-4">
                  {t("step1.registerDataSystem")}
                </Ui.m>

                <Ui.m color="foregroundMedium" className="my-3">
                  {t("step1.createIDNumber")}
                </Ui.m>

                <P.s color="foregroundMedium">{t("step1.createIDPoint1")}</P.s>
                <P.s color="foregroundMedium">{t("step1.createIDPoint2")}</P.s>
                <P.s color="foregroundMedium">{t("step1.createIDPoint3")}</P.s>

                <Banner className="my-2" variant="expired" description={t("step1.avoidSpecialCharactersSmall")} />

                <P.s color="foregroundMedium">{t("step1.createIDPoint4")}</P.s>

                <Image src={Img4} className="w-100 mt-3 mb-4 rounded-4" />

                <P.s color="foregroundMedium" className="mb-4">
                  {t("step1.checkFields")}
                </P.s>

                <div className={classes["banner"]}>
                  <div>
                    <Ui.m bold>{t("step1.finishedRegistration")}</Ui.m>
                    <P.s>{t("step1.activateStepAsCompleted")}</P.s>
                  </div>

                  <ToggleSwitch />
                </div>
              </BodySection>

              <BodySection
                eventKey="b"
                title={t("step2.title")}
                status={aroDetails?.altinnConnectionStatus}
                className="mb-1_half"
                customLeftItem={
                  <div className={classNames("me-2", classes["step-circle"])}>
                    <Ui.m bold>2</Ui.m>
                  </div>
                }
              >
                <P.s color="foregroundMedium" className="mb-3">
                  {t("step2.description")}
                </P.s>

                <div className={classes["container"]}>
                  <H.xxs color="foregroundMedium">{t("step2.loginForm.title")}</H.xxs>

                  <P.s color="foregroundMedium" className="my-3">
                    {t("step2.loginForm.enterPasswordFromLoginInfo")}
                  </P.s>

                  <TextField
                    isPasswordType
                    error={errors.loginPassword}
                    isTouched={touched.loginPassword}
                    label={t("step2.loginForm.loginPassword")}
                    value={values.loginPassword}
                    name="loginPassword"
                    onChange={handleChange}
                  />

                  <div className="my-4" style={{ color: scssVariables.primary1 }}>
                    &#9658;{" "}
                    <a style={{ color: scssVariables.primary1 }} className="text-decoration-underline">
                      {t("step2.loginForm.whereFindPassword")}
                    </a>
                  </div>

                  <TextField
                    type="number"
                    error={errors.birthNumber}
                    isTouched={touched.birthNumber}
                    label={t("step2.loginForm.birthNumber")}
                    value={values.birthNumber}
                    name="birthNumber"
                    onChange={handleChange}
                    maskNumber={{
                      thousandsSeparator: "",
                    }}
                  />

                  <div className="mt-3" style={{ color: scssVariables.primary1 }}>
                    &#9658;{" "}
                    <a style={{ color: scssVariables.primary1 }} className="text-decoration-underline">
                      {t("step2.loginForm.whyBirthAsk")}
                    </a>
                  </div>

                  <div className={classNames("my-3", classes["separator"])} />

                  <H.xxs color="foregroundMedium">{t("step2.dataSystemForm.title")}</H.xxs>

                  <P.s color="foregroundMedium" className="my-3">
                    {t("step2.dataSystemForm.description")}
                  </P.s>

                  <TextField
                    type="number"
                    name="altinnId"
                    value={values.altinnId}
                    error={errors.altinnId}
                    isTouched={touched.altinnId}
                    label={t("step2.dataSystemForm.idForAltinn")}
                    onChange={handleChange}
                    maskNumber={{
                      thousandsSeparator: "",
                    }}
                  />

                  <div className="my-3" style={{ color: scssVariables.primary1 }}>
                    &#9658;{" "}
                    <a style={{ color: scssVariables.primary1 }} className="text-decoration-underline">
                      {t("step2.dataSystemForm.whereFindPassOrLogin")}
                    </a>
                  </div>

                  <TextField
                    isPasswordType
                    error={errors.dataSystemPassword}
                    isTouched={touched.dataSystemPassword}
                    label={t("step2.dataSystemForm.dataSysPass")}
                    value={values.dataSystemPassword}
                    name="dataSystemPassword"
                    onChange={handleChange}
                  />

                  <div className="my-3" style={{ color: scssVariables.primary1 }}>
                    &#9658;{" "}
                    <a style={{ color: scssVariables.primary1 }} className="text-decoration-underline">
                      {t("step2.dataSystemForm.whereFindDataSysPass")}
                    </a>
                  </div>

                  <div className={classNames("my-3", classes["separator"])} />

                  {isVerifyFieldShown ? (
                    <div>
                      <div className={classNames("my-4", classes["separator"])} />

                      <Ui.m bold className="mb-1">
                        {t("step2.verifyCodeSent")}
                      </Ui.m>

                      <P.s color="foregroundMedium" className="mb-2">
                        {t("step2.codeSentToMobile", {
                          mobileNumber: verificationMobileNumber,
                        })}
                      </P.s>
                      <Col xs={5}>
                        <TextField
                          error={errors.verificationCode}
                          isTouched={touched.verificationCode}
                          label={t("step2.dataSystemForm.verificationCode")}
                          value={values.verificationCode}
                          name="verificationCode"
                          onChange={handleChange}
                        />
                      </Col>

                      <div className="mt-4">
                        <Button
                          className="me-1"
                          isDisabled={isLoading || !values.verificationCode}
                          onClick={handleSubmit as unknown as MouseEventHandler<HTMLButtonElement>}
                        >
                          {t("step2.verifyData")}
                        </Button>

                        <Button
                          variant="tertiary"
                          onClick={() => {
                            setFieldValue("isVerificationSuccess", false).then(() => {
                              handleSubmit();
                            });
                          }}
                        >
                          {t("step2.resendCode")}
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <>
                      <P.s color="foregroundMedium" className="my-3">
                        {t("step2.verifyInfoPressingTheButton")}
                      </P.s>
                      <Button
                        isLoading={isLoading}
                        isDisabled={
                          isLoading ||
                          !values.loginPassword ||
                          !values.birthNumber ||
                          !values.dataSystemPassword ||
                          !values.altinnId
                        }
                        onClick={handleSubmit as unknown as MouseEventHandler<HTMLButtonElement>}
                      >
                        {t("step2.receiveSms")}
                      </Button>
                    </>
                  )}
                </div>
              </BodySection>
            </Accordion>
          </>
        );
      }}
    </Formik>
  );
};

export default CreateLinkBody;
