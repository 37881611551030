import { FC, memo, useCallback, useEffect, useState } from "react";
import {
  ColumnDef,
  FilterFnOption,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  Row,
  useReactTable,
} from "@tanstack/react-table";
import { FormikErrors, useFormikContext } from "formik";

import Button from "common/components/atoms/Button/Button";
import { ImportErrorHandler, ImportTable } from "common/components/atoms/ImportTable";
import { manageRow } from "common/components/atoms/ImportTable/ImportTableHelper";
import { PlusIcon } from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

import { PostFormTransaction } from "../../types";

const t = createTranslation(TranslationNS.pages, "onboard.company.importTransaction");

type OwnershipTableProps = {
  columns: ColumnDef<PostFormTransaction>[];
  initialItems: PostFormTransaction;
  companyId?: number;
};

const Table: FC<OwnershipTableProps> = memo(({ columns, initialItems }) => {
  const { values, setValues, isSubmitting, isValid } = useFormikContext<PostFormTransaction[]>();

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [listErrors, setListErrors] = useState<FormikErrors<PostFormTransaction[]>>([]);

  const [globalFilter, setGlobalFilter] = useState("");

  const errorFilterData: FilterFnOption<PostFormTransaction> = useCallback(
    (row: Row<PostFormTransaction>) => {
      return !!listErrors[Number(row?.id)];
    },
    [listErrors]
  );

  const updateData = useCallback(
    (rowIndex: number, type: string) => {
      setValues(manageRow(values, rowIndex, type, initialItems));
    },
    [initialItems, setValues, values]
  );

  const table = useReactTable({
    data: values,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
    columnResizeMode: "onChange",
    enableColumnResizing: true,
    meta: { updateData, footerShow: true },
    state: {
      globalFilter: globalFilter,
    },
    globalFilterFn: errorFilterData,
    getFilteredRowModel: getFilteredRowModel(),
  });

  const handleAddNewRow = useCallback(() => {
    setValues([...values, { ...initialItems }]);
  }, [initialItems, setValues, values]);

  useEffect(() => {
    if (isSubmitting) {
      setShowErrorMessage(!isValid);
    }
  }, [isSubmitting, isValid]);

  return (
    <>
      <div>
        {showErrorMessage && (
          <ImportErrorHandler
            updateErrorCallback={setListErrors}
            isFilterActive={!!globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        )}
        <ImportTable table={table} />
        <div>
          {!globalFilter ? (
            <Button
              className="mt-3"
              isDisabled={!!globalFilter}
              onClick={handleAddNewRow}
              iconLeft={<PlusIcon />}
              variant="secondary"
            >
              {t("addTransaction")}
            </Button>
          ) : (
            <div />
          )}
        </div>
      </div>
    </>
  );
});

export default Table;
