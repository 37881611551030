import { useEffect } from "react";
import { Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

import { getPath } from "app/Router/RouterHelper";
import { Button, H, P, Tag, Ui } from "common/components/atoms";
import { ArrowRightIcon, LinkIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { useStoreActions } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import Img1 from "../assets/card-img.png";
import Img2 from "../assets/card-img-1.png";
import classes from "./styles.module.scss";

const [t, tMicro] = [
  createTranslation(TranslationNS.pages, "onboard.company.onboardSetup"),
  createTranslation(TranslationNS.pages, "onboard.company.getStarted"),
];

const CompanyOnboardSetup = () => {
  const navigate = useNavigate();

  const getDropdownsThunk = useStoreActions((state) => state.common.getDropdownsThunk);
  const setShareholders = useStoreActions((actions) => actions.companyOnboardingModel.setShareholders);
  const setIntegration = useStoreActions((actions) => actions.companyOnboardingModel.setIntegration);
  const setCompany = useStoreActions((actions) => actions.companyOnboardingModel.setCompany);
  const setCompanyType = useStoreActions((actions) => actions.companyOnboardingModel.setCompanyType);

  const fewOwnersCompanyHandler = () => {
    setCompanyType("few-owners");

    navigate(getPath(["onboard", "company", "getStarted"]));
  };

  const manyOwnersCompanyHandler = () => {
    setCompanyType("many-owners");

    navigate(getPath(["onboard", "company", "getStarted"]));
  };

  useEffect(() => {
    setCompanyType(null);
  }, [setCompanyType]);

  useEffect(() => {
    getDropdownsThunk();
  }, [getDropdownsThunk]);

  useEffect(() => {
    setShareholders([]);
    setIntegration({
      type: "all",
      data: null,
    });
    setCompany(null);
  }, [setShareholders, setCompany, setIntegration]);

  return (
    <div className={classes["wrap"]}>
      <H.s>{t("title")}</H.s>

      <div
        className={classNames("mt-5 p-3 d-flex mb-3 cursor-pointer", classes["card"])}
        onClick={fewOwnersCompanyHandler}
      >
        <div>
          <H.xs className="mb-1">{t("fewOwners")}</H.xs>

          <P.m color="foregroundMedium">{t("fewOwnersDescription")}</P.m>

          <div className="d-flex mt-2 gap-1">
            <Tag variant="closed" className={classes["badge"]}>
              <Ui.s>{t("badges.startup")}</Ui.s>
            </Tag>

            <Tag variant="closed" className={classes["badge"]}>
              <Ui.s>{t("badges.earlyStage")}</Ui.s>
            </Tag>

            <Tag variant="closed" className={classes["badge"]}>
              <Ui.s>{t("badges.establishWithFewOwners")}</Ui.s>
            </Tag>
          </div>
        </div>

        <div className="d-flex align-items-center">
          <Image src={Img1} />

          <ArrowRightIcon className="ms-3" fontSize={24} color={scssVariables.primary1} />
        </div>
      </div>

      <div className={classNames("p-3 d-flex cursor-pointer", classes["card"])} onClick={manyOwnersCompanyHandler}>
        <div>
          <H.xs className="mb-1">{t("manyOwners")}</H.xs>

          <P.m color="foregroundMedium">{t("manyOwnersDescription")}</P.m>

          <div className="d-flex mt-2 gap-1">
            <Tag variant="closed" className={classes["badge"]}>
              <Ui.s>{t("badges.growth")}</Ui.s>
            </Tag>

            <Tag variant="closed" className={classes["badge"]}>
              <Ui.s>{t("badges.establish")}</Ui.s>
            </Tag>

            <Tag variant="closed" className={classes["badge"]}>
              <Ui.s>{t("badges.startupLaunched")}</Ui.s>
            </Tag>
          </div>
        </div>

        <div className="d-flex align-items-center">
          <Image src={Img2} />

          <ArrowRightIcon className="ms-3" fontSize={24} color={scssVariables.primary1} />
        </div>
      </div>

      <Button
        as="a"
        size="s"
        target="_self"
        className="mt-6"
        rel="noreferrer"
        variant="tertiary"
        iconRight={<LinkIcon />}
        href={process.env.REACT_APP_UNI_MICRO_SRBANK_LANDING}
      >
        {tMicro("importFromUniMicroSRBank")}
      </Button>
    </div>
  );
};

export default CompanyOnboardSetup;
