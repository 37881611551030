import { FC } from "react";
import { Route, Routes } from "react-router-dom";

import { ROUTER_V2 } from "app/Router/RouterV2.types";
import MainLayout from "common/layout/MainLayout/MainLayout";

import AroOnboarding from "./pages/onboard/AroOnboarding";
import AroPricingTable from "./pages/pricing/aro-pricing-table";
import ReportPage from "./pages/report/main";

const AroRouter: FC = () => {
  return (
    <MainLayout>
      <Routes>
        {/*<Route element={<SomeAccessMiddleware />}>*/}
        <Route path={ROUTER_V2.aro.onboard} element={<AroOnboarding />} />
        <Route path={ROUTER_V2.aro.report} element={<ReportPage />} />
        <Route path={ROUTER_V2.aro.pricing} element={<AroPricingTable />} />
        {/*</Route>*/}
      </Routes>
    </MainLayout>
  );
};

export default AroRouter;
