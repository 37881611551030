import { useMemo } from "react";
import * as Yup from "yup";

import { AroFormValues } from "./types";

export const useAroForm = () => {
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        // TODO import schemas from separate forms
        companyInformation: Yup.object(),
        shareholdersAtYearStart: Yup.object(),
        transactions: Yup.object(),
        shareholdersAtYearEnd: Yup.object(),
        dividends: Yup.object(),
      }),
    []
  );

  const initialValues: AroFormValues = useMemo(() => {
    return {
      companyInformation: {
        companyName: "",
        orgNumber: "",
        postalCode: "",
        postOffice: "",
        contactPersons: [
          {
            name: "",
            companyName: "",
            role: "", // TODO role enum
            email: "",
            phoneNumber: "",
          },
        ],
      },
      shareholdersAtYearStart: {},
      transactions: {},
      shareholdersAtYearEnd: {},
      dividends: {},
    };
  }, []);

  return { validationSchema, initialValues };
};
