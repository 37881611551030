import { FC, memo, ReactNode } from "react";
import { Modal } from "react-bootstrap";
import cn from "classnames";

import { H } from "../Typography";

type PropsTypes = {
  show: boolean;
  onClose(): void;
  children: ReactNode;
  title?: string;
  background?: string;
};

const FullScreenView: FC<PropsTypes> = ({ show, onClose, children, title, background }) => {
  return (
    <Modal show={show} fullscreen={true} onHide={onClose} containerClassName="vh-100 vw-100">
      {title && (
        <Modal.Header closeButton className={cn("py-2 px-3", background)}>
          <H.xs>{title}</H.xs>
        </Modal.Header>
      )}
      <Modal.Body className={cn("h-100 overflow-scroll p-0", background)}>{children}</Modal.Body>
    </Modal>
  );
};

export default memo(FullScreenView);
