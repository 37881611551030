import { sortingParams } from "common/types/Collapsible.types";

export type TableType = "start" | "end";

export enum ShareholdersTableKeys {
  shareholderName = "shareholderName",
  isin = "isin",
  shareClass = "shareClass",
  numberOfShares = "numberOfShares",
  missingInfo = "missingInfo",
}

export type ShareholdersTableSorting = {
  field: ShareholdersTableKeys;
  sortDirection: sortingParams;
};

export type HeaderRow = {
  key: ShareholdersTableKeys;
  value: string;
  sortDisabled?: boolean;
};
