import { FC, memo, useCallback, useEffect, useMemo, useRef } from "react";
import { Image } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { Link, matchRoutes, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import cn from "classnames";
import { AnimatePresence, motion, useCycle } from "framer-motion";
import * as R from "ramda";

import { Button, P, Ui } from "common/components/atoms";
import { ChevronLeftIcon, InformationCircleOutlinedV2, UnlistedIcon, UserIcon } from "common/icons/svg";
import { HEADER_ID } from "common/utils/constants";
import { useStoreState } from "store/store";
import { TranslationNS } from "translation";
import { createTranslation } from "translation/helpers";

import { handleLogout } from "../../common/utils/functions";
import { getPath } from "../Router/RouterHelper";
import { ROUTER_V2 } from "../Router/RouterV2.types";
import BurgerToggle from "./components/BurgerToggle/BurgerToggle";
import ContextContent from "./ContextMenu/components/ContextContent/ContextContent";
import ContextCard from "./ContextMenu/ContextCard";
import classes from "./Header.module.scss";

const t = createTranslation(TranslationNS.layout, "header");

type NavMenuItemsProps = {
  routes: {
    to: string;
    isActive: boolean;
    title: string;
  }[];
  key: string;
};

const Header: FC = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const location = useLocation();
  const headerRef = useRef<HTMLDivElement>(null);
  const [isOpen, toggleOpen] = useCycle(false, true);

  const { isWizardLayout, isDarkTheme, isHeaderContextShown } = useStoreState((state) => state.app);
  const user = useStoreState((state) => state.account?.user);
  const activeCompanyId = useStoreState((state) => state.activeCompanyModel.companyId);
  const { HubEvents } = useStoreState((state) => state.company);

  const { isPublicRouteActive, isAuthenticated } = useStoreState((state) => state.authentication);

  const showRegistrationBanner = useMemo(() => {
    if (!activeCompanyId) {
      return false;
    }

    return user?.companies?.find((company) => company.id === activeCompanyId)?.notCompletedOnboarding;
  }, [user?.companies, activeCompanyId]);

  const onAuthPressHandler = () => {
    navigate("/");
  };

  const HubEventID = useMemo(() => R.defaultTo(1, HubEvents?.[0]?.id), [HubEvents]);

  const [profileMatches, companyMatches, receivingMatches] = useMemo(() => {
    const profileKeys = [{ path: ROUTER_V2.user.full }, { path: ROUTER_V2.myPortfolio.full }];
    const companyKeys = [
      { path: ROUTER_V2.equityManagement.full },
      { path: ROUTER_V2.attract.full },
      { path: ROUTER_V2.companySettings.full },
      { path: ROUTER_V2.companyProfile.full },
    ];

    const profileMatches = !!matchRoutes(profileKeys, location.pathname)?.length;
    const companyMatches = !!matchRoutes(companyKeys, location.pathname)?.length;

    const receivingMatches = location.pathname.includes(ROUTER_V2.receive.base);

    return [profileMatches, companyMatches, receivingMatches];
  }, [location.pathname]);

  const navMenuItems = useMemo<NavMenuItemsProps>(() => {
    return { routes: [], key: "none" };
  }, []);

  const rootPage = useMemo<string>(() => {
    const [, page] = location.pathname.split("/");

    return page;
  }, [location.pathname]);

  const preventScroll = useCallback((e: WheelEvent) => {
    e.preventDefault();
    e.stopPropagation();

    return false;
  }, []);

  const preventMooveScroll = useCallback((e: TouchEvent) => {
    e.preventDefault();
    e.stopPropagation();

    return false;
  }, []);

  const logoutHandler = () => {
    handleLogout(instance);
  };

  useEffect(() => {
    const enableScrolling = () => {
      headerRef.current?.removeEventListener("wheel", preventScroll, true);
      headerRef.current?.removeEventListener("touchmove", preventMooveScroll, true);
    };

    const disableScrolling = () => {
      headerRef.current?.addEventListener("wheel", preventScroll, true);
      headerRef.current?.addEventListener("touchmove", preventMooveScroll, true);
    };

    if (isOpen) {
      disableScrolling();
    } else {
      enableScrolling();
    }
  }, [isOpen, preventMooveScroll, preventScroll]);

  const url = useMemo(() => {
    if (isPublicRouteActive) {
      return getPath(["event"], { id: HubEventID });
    }

    return getPath(["user", "dashboard"]);
  }, [HubEventID, isPublicRouteActive]);

  if (rootPage === "onboard") {
    return null;
  }

  return (
    <div className={cn({ [classes["headerHide"]]: isWizardLayout })}>
      {showRegistrationBanner && companyMatches && (
        <div className={classes.banner}>
          <div className={cn("d-flex", classes.finishSignUp)}>
            <div className="d-flex">
              <span className={classes.icon}>
                <InformationCircleOutlinedV2 />
              </span>
              <div className={cn("ms-3", classes.text)}>
                <Ui.m bold>{t("finishSignUp.title")}</Ui.m>
                <P.s className="mt-1">{t("finishSignUp.description")}</P.s>
              </div>
            </div>

            <div className="d-flex">
              <Link to="https://www.unlisted.ai/kontakt-oss">
                <Button as="span" variant="tertiary">
                  {t("finishSignUp.contactSupport")}
                </Button>
              </Link>
              <Link to={getPath(["onboard", "company", "companyInformation"], { companyId: activeCompanyId })}>
                <Button as={"span"} className="ms-1" variant="primary">
                  {t("finishSignUp.finishRegistration")}
                </Button>
              </Link>
            </div>
          </div>
        </div>
      )}

      {receivingMatches ? (
        <div className={classes.bannerReceiver}>
          <div
            className={cn("d-flex justify-content-start", classes.finishSignUp, {
              [classes["receiver"]]: true,
            })}
          >
            <span className={classes.icon}>
              <UserIcon />
            </span>

            <div className={cn("ms-3", classes.text)}>
              <P.s>
                {t.el("loggedInAs", {
                  components: [<strong key={1} />],
                  values: {
                    user: `${user?.firstName} ${user?.lastName}`,
                    email: user?.email,
                  },
                })}
              </P.s>
            </div>

            <Button variant="tertiary" className="ms-auto" onClick={logoutHandler}>
              {t("useAnotherAccount")}
            </Button>
          </div>
        </div>
      ) : null}

      <header className={classes["header"]} id={HEADER_ID} ref={headerRef}>
        <div className={classes["header-left"]}>
          <Link className={cn("me-6", classes["unlisted-link"])} to={url}>
            <motion.div
              initial={{
                width: 28,
                height: 28,
              }}
              animate={{
                width: isPublicRouteActive ? 43 : 28,
                height: isPublicRouteActive ? 53 : 28,
              }}
            >
              <UnlistedIcon
                width="100%"
                height="100%"
                className={cn(classes["unlisted-icon"], {
                  [classes["dark-theme"]]: isDarkTheme,
                })}
              />
            </motion.div>
          </Link>
          <AnimatePresence key={navMenuItems?.key}>
            <motion.div
              className={cn("d-flex", classes["nav-items"])}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              {navMenuItems?.routes?.map((el, index) => (
                <Nav.Link
                  as={NavLink}
                  to={el.to}
                  key={index}
                  className={cn("ui-s", "fw-500", "ms-4", classes["navigation-link"], {
                    [classes["active"]]: el.isActive,
                    [classes["dark-theme"]]: isDarkTheme,
                  })}
                  // @ts-ignore
                  disabled={el.disabled}
                >
                  {el.title}
                </Nav.Link>
              ))}
            </motion.div>
          </AnimatePresence>

          {isPublicRouteActive ? (
            <div className={classes["header-public"]}>
              {rootPage === "company-details" && (
                <Button
                  size="s"
                  variant="tertiary"
                  data-testid="app-header-btn-test-id"
                  className={cn(classes["back-btn"], "ms-3")}
                  onClick={() => {
                    navigate(getPath(["event"], { id: HubEventID }));
                  }}
                >
                  <ChevronLeftIcon />
                  {t("back")}
                </Button>
              )}
            </div>
          ) : null}
        </div>

        <div className={classes["header-right"]}>
          {isAuthenticated ? (
            isHeaderContextShown ? (
              <>
                {/* <NotificationsMenu /> */}
                <ContextCard />

                {user?.profileImage && (
                  <Image alt="profile" src={user?.profileImage} className={cn("me-1", classes["profile-image"])} />
                )}

                <motion.div initial={false} className={classes["burger-menu"]} animate={isOpen ? "open" : "closed"}>
                  <BurgerToggle isOpen={isOpen} toggle={toggleOpen} />
                </motion.div>
              </>
            ) : null
          ) : (
            <>
              <Button variant="tertiary" onClick={onAuthPressHandler} style={{ marginRight: 12 }}>
                {t("login")}
              </Button>
              <Button onClick={onAuthPressHandler}>{t("singUp")}</Button>
            </>
          )}
        </div>
      </header>
      <ContextContent
        mobile
        open={isOpen}
        setIsOpen={() => {
          toggleOpen();
        }}
      />
    </div>
  );
};

export default memo(Header);
