import { sortingParams } from "common/types/Collapsible.types";

export enum TransactionsTableKeys {
  date = "date",
  category = "category",
  type = "type",
  numberOfShares = "numberOfShares",
  price = "price",
}

export type TransactionsTableSorting = {
  field: TransactionsTableKeys;
  sortDirection: sortingParams;
};

export type HeaderRow = {
  key: TransactionsTableKeys;
  value: string;
  sortDisabled?: boolean;
};
