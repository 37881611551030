import { useMemo } from "react";
import * as Yup from "yup";

import { createTranslation, TranslationNS } from "translation";

import { Shareholder, ShareholderEditFormValues } from "../../types";

const t = createTranslation(TranslationNS.validation);

export const fields = {
  stakeholderId: "stakeholderId",
  shareholderName: "shareholderName",
  isin: "isin",
  numberOfShares: "numberOfShares",
} as const;

const initValue: ShareholderEditFormValues = {
  [fields.stakeholderId]: 0,
  [fields.shareholderName]: "",
  [fields.isin]: "",
  [fields.numberOfShares]: 0,
};

const objectSchema = Yup.object().shape({
  // other fields are not editable
  [fields.isin]: Yup.string().required(t("required")),
});

const useEditTransactionsForm = (shareholders: Shareholder[]) => {
  const initialValues: ShareholderEditFormValues[] = useMemo(() => {
    if (shareholders.length === 0) {
      return [initValue];
    }

    return shareholders.map((shareholder) => ({
      [fields.stakeholderId]: shareholder.stakeholderId,
      [fields.shareholderName]: shareholder.shareholderName,
      [fields.isin]: shareholder.isin || "",
      [fields.numberOfShares]: shareholder.numberOfShares,
    }));
  }, [shareholders]);

  const validationSchema = useMemo(() => {
    return Yup.array().of(objectSchema);
  }, []);

  return { validationSchema, initialValues };
};

export default useEditTransactionsForm;
