import { FC, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Formik, FormikConfig } from "formik";

import { Button, Ui } from "common/components/atoms";
import { ArrowLeftIcon } from "common/icons/svg";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import WizardLayout from "common/layout/WizardLayout/WizardLayout";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { aroService } from "../../../../../../service/aro-service";
import { ShareholderEditFormValues } from "../../types";
import Table from "./table";
import useEditShareholdersColumns from "./use-edit-shareholders-columns";
import useEditShareholdersForm from "./use-edit-shareholders-form";

type PT = {
  onClose(): void;
};

const t = createTranslation(TranslationNS.pages, "aro.main.steps.shareholdersAtYearEnd.body.shareholdersEditForm");

const ShareholdersEditForm: FC<PT> = ({ onClose }) => {
  const { companyId } = useParams<{ companyId?: string }>();

  const shareholdersAtYearEnd = useStoreState((state) => state.aroModel.shareholdersAtYearEnd);
  const getAroDetails = useStoreActions((actions) => actions.aroModel.getAroDetailsThunk);
  const getShareholdersAtYearEnd = useStoreActions((actions) => actions.aroModel.getShareholdersAtYearEndThunk);

  const { validationSchema, initialValues } = useEditShareholdersForm(shareholdersAtYearEnd?.shareholders || []);

  const { columns } = useEditShareholdersColumns();

  const handleSubmit = useCallback<FormikConfig<ShareholderEditFormValues[]>["onSubmit"]>(
    async (values) => {
      try {
        if (companyId) {
          const shareholders = values.map((shareholder) => {
            const { numberOfShares, ...otherFields } = shareholder;
            return otherFields;
          });

          const result = await aroService.postShareholdersIsins({ companyId, stakeholders: shareholders });

          if (result) {
            notify("success", true, "success");
            getAroDetails(companyId);
            getShareholdersAtYearEnd(companyId);
            onClose();
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    [companyId, getAroDetails, getShareholdersAtYearEnd, onClose]
  );

  return (
    <WizardLayout>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ submitForm, isSubmitting }) => (
          <>
            <WizardLayout.Header title={t("title")} />
            <div className="d-flex justify-content-between align-items-center px-7">
              <Button onClick={onClose} isOnlyIcon isDisabled={isSubmitting} variant="secondary">
                <ArrowLeftIcon />
              </Button>
              <Ui.l>{t("paragraph")}</Ui.l>
              <Button isLoading={isSubmitting} onClick={submitForm}>
                {t("submit")}
              </Button>
            </div>
            <WizardContent hideSteps>
              <Table columns={columns} />
            </WizardContent>
          </>
        )}
      </Formik>
    </WizardLayout>
  );
};

export default ShareholdersEditForm;
