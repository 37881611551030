import { FC, SyntheticEvent, useContext, useMemo } from "react";
import { AccordionContext, useAccordionButton } from "react-bootstrap";

import { ChevronDownIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";

type TogglePropsTypes = {
  eventKey: string;
  itemIdToOpen: string | null;
};

const ProgrammaticAccordionToggle: FC<TogglePropsTypes> = ({ eventKey, itemIdToOpen }) => {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey);

  if (String(eventKey) === String(itemIdToOpen) && eventKey !== activeEventKey) {
    const event = new Event("custom event");
    decoratedOnClick(event as unknown as SyntheticEvent);
  }

  const isToggleActive = useMemo(() => {
    if (!activeEventKey) {
      return false;
    }
    if (typeof activeEventKey === "string") {
      return activeEventKey === eventKey;
    }

    if (typeof activeEventKey === "object") {
      return activeEventKey.find((key) => key === eventKey);
    }
  }, [activeEventKey, eventKey]);

  return (
    <ChevronDownIcon
      fontSize={24}
      color={scssVariables.foregroundMedium}
      direction={isToggleActive ? "top" : "bottom"}
    />
  );
};

export default ProgrammaticAccordionToggle;
