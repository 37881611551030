import { FC, useCallback } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { getPath } from "app/Router/RouterHelper";
import WithRouteAnimation from "common/HOC/WithRouteAnimation";
import { useStoreState } from "store/store";

import { User } from "../../PostponedComponents/onboard-manager-wizard/wizard-steps";
import classes from "./OnboardUserTerms.module.scss";

const OnboardUserTerms: FC<{ nextStep?: string }> = ({ nextStep }) => {
  const navigate = useNavigate();
  const aroOnboarding = useStoreState((state) => state.companyOnboardingModel.aroOnboarding);

  const handleNext = useCallback(() => {
    if (aroOnboarding.isActive) {
      navigate(getPath(["aro", "onboard"]));
      return;
    }

    navigate(nextStep || "/onboard/company");
  }, [aroOnboarding.isActive, navigate, nextStep]);

  return (
    <Container fluid className="d-flex font-secondary" data-testid="onboard-user-terms-container-test-id">
      <Container className="d-flex flex-column m-auto p-0">
        <div className={classes.wrap}>
          <User isDisabled={false} onSubmit={handleNext} />
        </div>
      </Container>
    </Container>
  );
};

export default WithRouteAnimation(OnboardUserTerms);
