import { useMemo } from "react";
import { defaultTo } from "ramda";
import * as Yup from "yup";

import { TransactionCategory, TransactionTypes } from "pages/equity-management/ownership/transactions/types";
import { createTranslation, TranslationNS } from "translation";

import {
  BuySellType,
  GetFormTransaction,
  IssueShareType,
  PostFormTransaction,
  ShareClass,
  TransactionCategoryDto,
} from "../../types";

const t = createTranslation(TranslationNS.validation);

export const fields = {
  transactedAt: "transactedAt",
  transactionCategoryId: "transactionCategoryId",
  transactionTypeId: "transactionTypeId",
  numberOfShares: "numberOfShares",
  sharePrice: "sharePrice",
  shareClassId: "shareClassId",
  toStakeholderId: "toStakeholderId",
  fromStakeholderId: "fromStakeholderId",

  transactionId: "transactionId",
  total: "total",
} as const;

export const initValues = {
  [fields.transactionCategoryId]: TransactionCategory.Issue,
  [fields.transactedAt]: "",
  [fields.transactionTypeId]: TransactionTypes.RsaSharesIssued,
  [fields.numberOfShares]: 0,
  [fields.sharePrice]: 0,
  [fields.shareClassId]: 0,
  [fields.toStakeholderId]: 0,
  [fields.fromStakeholderId]: 0,
};

const useEditTransactionsForm = (
  transactions: GetFormTransaction[],
  transactionCategories?: TransactionCategoryDto[],
  issueShareTypes?: IssueShareType[],
  buySellTypes?: BuySellType[],
  shareClasses?: ShareClass[]
) => {
  const initialValues = useMemo<PostFormTransaction[]>(() => {
    if (transactions.length > 0) {
      return transactions.map((transaction) => {
        const source =
          transaction.transactionCategoryId === TransactionCategory.Issue
            ? -1
            : transaction.transactionCategoryId === TransactionCategory.Sell
            ? transaction.fromStakeholderId
            : 0;

        return {
          [fields.transactionId]: defaultTo(undefined, transaction.transactionId),
          [fields.transactionCategoryId]: defaultTo(TransactionCategory.Issue, transaction.transactionCategoryId),
          [fields.transactedAt]: defaultTo("", transaction.transactedAt),
          [fields.transactionTypeId]: defaultTo(TransactionTypes.RsaSharesIssued, transaction.transactionTypeId),
          [fields.numberOfShares]: defaultTo(0, transaction.numberOfShares),
          [fields.sharePrice]: defaultTo(0, transaction?.sharePrice),
          [fields.shareClassId]: defaultTo(shareClasses?.[0]?.id || 0, transaction.shareClassId),
          [fields.toStakeholderId]: defaultTo(0, transaction.toStakeholderId),
          [fields.fromStakeholderId]: source,
        };
      });
    }

    return [initValues];
  }, [shareClasses, transactions]);

  const validationSchema = useMemo(() => {
    const objectSchema = Yup.object().shape({
      [fields.transactedAt]: Yup.string().required(t("required")),
      [fields.numberOfShares]: Yup.number()
        .nullable(true)
        .integer(t("integer"))
        .min(1, t("minNumber", { number: 1 }))
        .required(t("required")),
      [fields.sharePrice]: Yup.number()
        .nullable(true)
        .min(0.000001, t("minNumber", { number: "0,000001" }))
        .required(t("required")),
      [fields.shareClassId]: Yup.number().min(0, t("required")).required(t("required")),
      [fields.toStakeholderId]: Yup.number().min(0, t("required")).required(t("required")),
      [fields.fromStakeholderId]: Yup.number().min(0, t("required")).required(t("required")),
    });

    return Yup.array().of(objectSchema);
  }, []);

  return { validationSchema, initialValues };
};

export default useEditTransactionsForm;
