import { FC, useCallback } from "react";
import { useFormikContext } from "formik";

import { Button, ChecksGroup, P, TextField } from "common/components/atoms";
import { ChevronRightIcon } from "common/icons/svg";
import { OnboardingUserPostDTO } from "store/modelTypes";
import { createTranslation, TranslationNS } from "translation";

import { userFields } from "./shema";
import classes from "./user.module.scss";
import UserAvatarUploader from "./user-avatar-uploader/UserAvatarUploader";

const t = createTranslation(TranslationNS.pages, "managerOnboarding.userStep");

const UserFormFields: FC<{ isOnboarded?: boolean }> = ({ isOnboarded }) => {
  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isSubmitting, isValid } =
    useFormikContext<OnboardingUserPostDTO>();

  const onUploadImage = useCallback(
    (image: any) => {
      setFieldValue(userFields.profileImage, image);
    },
    [setFieldValue]
  );

  return (
    <form onSubmit={handleSubmit}>
      <div className="d-flex align-items-center">
        <div className="me-11 flex-grow-1">
          <TextField
            className="mb-3"
            label={t("firstName")}
            value={values.firstName}
            isTouched={touched.firstName}
            error={errors.firstName}
            name={userFields.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <TextField
            className="mb-3"
            label={t("lastName")}
            value={values.lastName}
            isTouched={touched.lastName}
            error={errors.lastName}
            name={userFields.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <input value={values.email} name={userFields.email} type="hidden" />
        </div>
        <UserAvatarUploader
          title={t("avatarCard.title")}
          btnText={values.profileImage ? t("avatarCard.replaceBtn") : t("avatarCard.uploadBtn")}
          previewImageUrl={values.profileImage}
          onUploadImage={onUploadImage}
        />
      </div>
      <div className="mt-4">
        <ChecksGroup
          className="mb-1"
          isTouched={touched.hasAcceptedTermsOfService}
          error={errors.hasAcceptedTermsOfService}
        >
          <ChecksGroup.Check
            label={
              <>
                {t("checkTerms")}{" "}
                <a
                  target="_blank"
                  href="https://assets-global.website-files.com/642d7f29e2d90c185e611d17/6633956fcc1f9b6ac35e1add_StandardvilkarforbrukavskytjenesterfraUnlisted.pdf"
                  className={classes.terms}
                  data-testid="onboard-user-terms-test-id"
                  rel="noreferrer"
                >
                  {t("checkTermsLink")}
                </a>
              </>
            }
            checked={values.hasAcceptedTermsOfService}
            name={userFields.hasAcceptedTermsOfService}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </ChecksGroup>
        <ChecksGroup
          className="mb-2"
          isTouched={touched.hasAcceptedPrivacyPolicy}
          error={errors.hasAcceptedPrivacyPolicy}
        >
          <ChecksGroup.Check
            label={
              <>
                {t("checkPrivacy")}{" "}
                <a
                  target="_blank"
                  href="https://assets-global.website-files.com/642d7f29e2d90c185e611d17/6633956fcc1f9b6ac35e1add_StandardvilkarforbrukavskytjenesterfraUnlisted.pdf"
                  className={classes.terms}
                  data-testid="onboard-user-terms-test-id"
                  rel="noreferrer"
                >
                  {t("checkTermsLink")}
                </a>
              </>
            }
            checked={values.hasAcceptedPrivacyPolicy}
            name={userFields.hasAcceptedPrivacyPolicy}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </ChecksGroup>
        <P.s className={classes.privacy}>
          {t("privacyPolicyDescription")}{" "}
          <a
            href="https://en.unlisted.ai/personvern-policy"
            target="_blank"
            className={classes.terms}
            data-testid="onboard-user-terms-test-id"
            rel="noreferrer"
          >
            {t("checkPrivacyLink")}.
          </a>
        </P.s>
        <Button
          className="mt-4"
          data-testid="onboard-user-create-account-btn"
          type="submit"
          isDisabled={!isValid || isSubmitting || !!isOnboarded}
          isLoading={isSubmitting}
          iconRight={<ChevronRightIcon />}
        >
          {t("createBtn")}
        </Button>
      </div>
    </form>
  );
};
export default UserFormFields;
