import { useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { Button, InfoAlert, P } from "common/components/atoms";
import FullScreenView from "common/components/atoms/full-screen-view/full-screen-view";
import useFullScreenView from "common/components/atoms/full-screen-view/use-full-screen-view";
import { WarningExclamationMarkIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import BodySection from "../../step-container/body-container/body-section";
import ShareholdersTable from "../shareholders-table/shareholders-table";
import ShareholdersEditForm from "./shareholders-edit-form/shareholdes-edit-form";
import classes from "./styles.module.scss";

const t = createTranslation(TranslationNS.pages, "aro.main.steps.shareholdersAtYearEnd.body");

const ShareholdersAtYearEndBody = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const { isFullScreenOpen, handleOpenFullScreen, handleCloseFullScreen } = useFullScreenView();

  const shareholdersAtYearEnd = useStoreState((state) => state.aroModel.shareholdersAtYearEnd);
  const isLoading = useStoreState((state) => state.aroModel.isShareholdersAtYearEndLoading);
  const getShareholderAtYearEnd = useStoreActions((store) => store.aroModel.getShareholdersAtYearEndThunk);

  useEffect(() => {
    if (companyId) {
      getShareholderAtYearEnd(companyId);
    }
  }, [companyId, getShareholderAtYearEnd]);

  return (
    <>
      <Accordion flush defaultActiveKey="end">
        <BodySection eventKey="end" title={t("listOfShareholders.title")} hideToggle>
          <InfoAlert
            type="Info"
            className="mb-3"
            customContent={
              <div className="d-flex">
                <WarningExclamationMarkIcon fontSize={36} className="me-2" color={scssVariables.information700} />
                <P.s>{t("warningPlate.paragraph")}</P.s>
                <Button
                  onClick={handleOpenFullScreen}
                  variant="information"
                  style={{ backgroundColor: scssVariables.information700, color: scssVariables.surface1 }}
                >
                  {t("warningPlate.btn")}
                </Button>
              </div>
            }
          />

          <ShareholdersTable
            type="end"
            isLoading={isLoading}
            shareholders={shareholdersAtYearEnd?.shareholders || []}
          />
        </BodySection>
      </Accordion>

      <FullScreenView show={isFullScreenOpen} onClose={handleCloseFullScreen} background={classes.background}>
        <ShareholdersEditForm onClose={handleCloseFullScreen} />
      </FullScreenView>
    </>
  );
};

export default ShareholdersAtYearEndBody;
