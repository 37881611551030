import { useMemo, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";

import { OptionsType } from "common/components/atoms/Dropdown/Dropdown";
import { Actions, DateInput, SelectInput, TextInput } from "common/components/atoms/ImportTable";
import useCurrencyById from "common/hooks/useCurrencyById";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import ShareholderField from "pages/equity-management/ownership/transactions/components/forms/capital-increase/steps/capital-increase-transactions-table/TransactionTable/components/shareholder-field";
import { TransactionCategory } from "pages/equity-management/ownership/transactions/types";
import TransactionField from "pages/onboard/company/import-transaction/TransactionField/TransactionField";
import { createTranslation, TranslationNS } from "translation";

import {
  BuySellType,
  IssueShareType,
  PostFormTransaction,
  ShareClass,
  Stakeholder,
  TransactionCategoryDto,
} from "../../types";
import SourceField from "./fields/source-filed";
import classes from "./styles.module.scss";
import { fields } from "./use-edit-transactions-form";

const t = createTranslation(TranslationNS.pages, "aro.main.steps.transactions.body.editTransactionsTable.headers");
const tBody = createTranslation(TranslationNS.pages, "aro.main.steps.transactions.body.editTransactionsTable.body");

const useEditTransactionsColumns = (
  refetchData: () => Promise<void>,
  shareClasses?: ShareClass[],
  transactionCategories?: TransactionCategoryDto[],
  issueSharesTypes?: IssueShareType[],
  buySellTypes?: BuySellType[],
  stakeholders?: Stakeholder[],
  currencyId?: number
) => {
  const { currencySymbol } = useCurrencyById(currencyId);
  const fNumber = useFormatNumbers(currencySymbol);

  const [createdStakeholdersId, setCreatedStakeholdersId] = useState<Record<string, number | null>>({});

  const categories = useMemo(() => {
    return (
      transactionCategories?.map((category) => ({
        id: category.transactionCategoryId,
        name: category.transactionCategoryName,
      })) || []
    );
  }, [transactionCategories]);

  const issueTypes = useMemo(() => {
    return (
      issueSharesTypes?.map((type) => ({
        id: type.transactionTypeId,
        name: type.transactionTypeName,
      })) || []
    );
  }, [issueSharesTypes]);

  const sellTypes = useMemo(() => {
    return (
      buySellTypes?.map((type) => ({
        id: type.transactionTypeId,
        name: type.transactionTypeName,
      })) || []
    );
  }, [buySellTypes]);

  const shareClassesWithEmpty = useMemo(() => {
    return shareClasses ? [{ id: 0, name: tBody("notSelected") }, ...shareClasses] : [];
  }, [shareClasses]);

  const stakeholdersWithEmpty = useMemo(() => {
    const createNew = {
      id: -2,
      name: tBody("addStakeholder"),
    };

    return stakeholders ? [createNew, ...stakeholders] : [createNew];
  }, [stakeholders]);

  const sourceWithEmpty = useMemo(() => {
    return [{ id: 1, name: tBody("newShares") }];
  }, []);

  const columns = useMemo<ColumnDef<PostFormTransaction>[]>(() => {
    const cells: ColumnDef<PostFormTransaction>[] = [
      {
        header: "#",
        accessorKey: "transactionNumber",
        cell: (props) => <div className={classes.transactionNumber}>{props.row.index + 1}</div>,
        size: 50,
        footer: () => {
          return <span>{t("total")}</span>;
        },
      },
      {
        header: t("transactionDate") + " *",
        accessorKey: fields.transactedAt,
        cell: (props) => (
          <DateInput rowIndex={props.row.index} columnId={props.column.id} placeholder="dd.mm.yyyy" isWithTimeSelect />
        ),
        minSize: 170,
      },
      {
        header: t("category"),
        accessorKey: fields.transactionCategoryId,
        cell: (props) => (
          <TransactionField
            rowIndex={props.row.index}
            columnId={props.column.id}
            options={categories}
            isSearchable={false}
            resetValue={fields.fromStakeholderId}
            placeholder={tBody("notSelected")}
            isClearable
          />
        ),
        minSize: 210,
      },
      {
        header: t("type"),
        accessorKey: fields.transactionTypeId,
        cell: (props) => {
          const selectedCategory = props.row.original[fields.transactionCategoryId];

          let options: OptionsType[] = [];

          if (selectedCategory === TransactionCategory.Issue) {
            options = issueTypes || [];
          }

          if (selectedCategory === TransactionCategory.Sell) {
            options = sellTypes || [];
          }
          return (
            <TransactionField
              rowIndex={props.row.index}
              columnId={props.column.id}
              options={options}
              isSearchable={false}
              resetValue={fields.fromStakeholderId}
              placeholder={tBody("notSelected")}
              isClearable
            />
          );
        },
        minSize: 210,
      },
      {
        header: t("numberOfShares") + " *",
        accessorKey: fields.numberOfShares,
        cell: (props) => (
          <TextInput
            rowIndex={props.row.index}
            columnId={props.column.id}
            className={classes.shareClassAlign}
            type="number"
          />
        ),
        minSize: 170,
        footer: ({ table }) => {
          const total = table
            .getFilteredRowModel()
            .rows.reduce((sum, row) => +sum + +(row.original.numberOfShares || 0), 0);

          return (
            <div className={"text-end"}>
              <span>{fNumber(total, "amount")}</span>
            </div>
          );
        },
        size: 80,
        meta: {
          headClass: "text-end",
        },
      },
      {
        header: t("pricePerShare") + " *",
        accessorKey: fields.sharePrice,
        cell: (props) => (
          <TextInput
            rowIndex={props.row.index}
            columnId={props.column.id}
            className={classes.shareClassAlign}
            type="number"
            iconRight={currencySymbol}
          />
        ),
        minSize: 160,
        meta: {
          headClass: "text-end",
        },
      },
      {
        header: t("total"),
        accessorKey: fields.total,
        cell: (props) => {
          const numberOfShares = (props.row.getValue(fields.numberOfShares) as number) || 0;
          const price = (props.row.getValue(fields.sharePrice) as number) || 0;

          return <div className={classes.transactionNumber}>{fNumber(numberOfShares * price, "value")}</div>;
        },
        size: 140,
        meta: {
          headClass: "text-end",
        },
      },
      {
        header: t("shareClass") + " *",
        accessorKey: fields.shareClassId,
        cell: (props) => (
          <SelectInput
            rowIndex={props.row.index}
            columnId={props.column.id}
            options={shareClassesWithEmpty}
            isSearchable
            placeholder={tBody("notSelected")}
          />
        ),
        minSize: 170,
      },
      {
        header: t("shareholder") + " *",
        accessorKey: fields.toStakeholderId,
        cell: (props) => {
          const cellId = props?.cell?.id || "";

          const setStakeholderId = (stakeholderId: number | null) => {
            setCreatedStakeholdersId({
              ...createdStakeholdersId,
              [cellId]: stakeholderId,
            });
          };

          return (
            <ShareholderField
              rowIndex={props.row.index}
              columnId={props.column.id}
              stakeholdersOptions={stakeholdersWithEmpty}
              refetchCallback={refetchData}
              createdStakeholderId={createdStakeholdersId[cellId]}
              setCreatedStakeholderId={setStakeholderId}
              placeholder={tBody("notSelected")}
            />
          );
        },
        minSize: 170,
      },
      {
        header: t("source") + " *",
        accessorKey: fields.fromStakeholderId,
        cell: (props) => {
          const cellId = props?.cell?.id || "";

          const selectedCategory = props.row.original[fields.transactionCategoryId];

          if (selectedCategory === TransactionCategory.Issue) {
            return (
              <SourceField
                rowIndex={props.row.index}
                columnId={props.column.id}
                sourceOptions={sourceWithEmpty}
                placeholder={tBody("notSelected")}
              />
            );
          }

          if (selectedCategory === TransactionCategory.Sell) {
            const setStakeholderId = (stakeholderId: number | null) => {
              setCreatedStakeholdersId({
                ...createdStakeholdersId,
                [cellId]: stakeholderId,
              });
            };

            return (
              <ShareholderField
                rowIndex={props.row.index}
                columnId={props.column.id}
                stakeholdersOptions={stakeholdersWithEmpty}
                refetchCallback={refetchData}
                createdStakeholderId={createdStakeholdersId[cellId]}
                setCreatedStakeholderId={setStakeholderId}
                placeholder={tBody("notSelected")}
              />
            );
          }
        },
        minSize: 170,
      },
      {
        accessorKey: "actions",
        header: "",
        cell: ({ row, table }) => (
          <Actions
            rowIndex={row.index}
            updateData={table.options.meta?.updateData}
            globalFilter={table.getState().globalFilter}
            maxFreeStakeholders={null}
          />
        ),
        maxSize: 40,
      },
    ];

    return cells;
  }, [
    categories,
    issueTypes,
    sellTypes,
    fNumber,
    currencySymbol,
    shareClassesWithEmpty,
    stakeholdersWithEmpty,
    refetchData,
    createdStakeholdersId,
    sourceWithEmpty,
  ]);

  return { columns };
};

export default useEditTransactionsColumns;
