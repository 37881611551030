import { FC, memo, MouseEventHandler, ReactNode, useCallback, useMemo } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import axios from "axios";
import { motion } from "framer-motion";
import { and, insert, isNil } from "ramda";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, ContextMenu, ContextMenuProps, Tag, Tooltip, Ui } from "common/components/atoms";
import { getCurrentAgreementContext } from "common/components/organisms/agreements/components/common/functions";
import { InstrumentTypesIdsEnum, InstrumentTypesNamesEnum, PlanStatusesBasedOnAPIStatusId } from "common/enums/enum";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import {
  CheckBadgeIcon,
  CheckIcon,
  CommonFileIcon,
  DeleteIcon,
  EditIcon,
  EmailActionSend,
  MenuTabBarVerticalIcon,
  MultiplyIcon,
  RemoveIcon,
} from "common/icons/svg";
import { usePlanStatus } from "common/plan/planUtils";
import { OwnershipProgramTableData } from "common/types/Collapsible.types";
import { scssVariables } from "common/utils/constants";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { notify } from "common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "translation";

import ManagePlansContext from "../../managePlansContext";
import classes from "./ProgramContainer.module.scss";

const [t, tNotify] = [
  createTranslation(TranslationNS.pages, "company.ownershipPlans.table"),
  createTranslation(TranslationNS.pages, "company.ownershipPlans.sendInvite"),
];

type ProgramTableRowProps = {
  programID?: number;
  isOneOff?: boolean;
  row: OwnershipProgramTableData;
};

const ProgramTableRow: FC<ProgramTableRowProps> = ({ row, programID, isOneOff }) => {
  const { hasFullAccess } = useFeatures(FEATURES.managePlans);
  const fNumber = useFormatNumbers();
  const status = usePlanStatus(row.statusId);
  const isSO = row.instrumentTypeId === InstrumentTypesIdsEnum.OPTION;

  const { setEditPlanInfo, setDeletePlanInfo, setSendInviteInfo, setGrantPlanInfo, getPlanTerminationDetailsThunk } =
    ManagePlansContext.useStoreActions((actions) => actions);

  const { getAgreementDetailsThunk } = getCurrentAgreementContext(
    row.instrumentTypeName as InstrumentTypesNamesEnum
  ).useStoreActions((actions) => actions);

  const handleClickView = useCallback<MouseEventHandler>(() => {
    getAgreementDetailsThunk(row.id);
  }, [row, getAgreementDetailsThunk]);

  const handleClickEdit = useCallback(
    (e: any) => {
      e?.stopPropagation();

      setEditPlanInfo({ ...row, programId: programID });
    },
    [programID, row, setEditPlanInfo]
  );

  const handleClickDelete = useCallback(() => {
    setDeletePlanInfo(row);
  }, [row, setDeletePlanInfo]);

  const handleClickTerminate = useCallback(() => {
    getPlanTerminationDetailsThunk(row.id);
  }, [row.id, getPlanTerminationDetailsThunk]);

  const sendInviteToManagerAction = useCallback(async () => {
    try {
      const formData = new FormData();
      formData.append("planId", String(row.id));

      const request = await axios.post(`/api/ownership/plan/resend-manager-email/${row.id}`, formData);
      if (request.status === 200) {
        notify(tNotify("emailSent"), true, "success");
      }
    } catch (e) {
      console.warn({ e });
    }
  }, [row.id]);

  const handleSendInvite = useCallback(
    (e: any) => {
      e?.stopPropagation();

      if (row.statusId === PlanStatusesBasedOnAPIStatusId.waitingForManager) {
        sendInviteToManagerAction().then(() => {
          document.body.click();
        });
        return;
      }

      setSendInviteInfo(row);
    },
    [row, sendInviteToManagerAction, setSendInviteInfo]
  );

  const handleGrantPlan = useCallback(
    (e: any) => {
      e?.stopPropagation();

      setGrantPlanInfo(row);
    },
    [row, setGrantPlanInfo]
  );

  const TdView = useCallback<FC<{ children?: ReactNode } & JSX.IntrinsicElements["td"]>>(
    ({ children, ...props }) => {
      return (
        <td className={classes.viewPlan} onClick={handleClickView} {...props}>
          {children}
        </td>
      );
    },
    [handleClickView]
  );

  const menuItems = useMemo<ContextMenuProps["items"]>(() => {
    let menuItems: ContextMenuProps["items"] = [
      {
        key: "grant",
        label: t("grant"),
        icon: <CheckBadgeIcon />,
        onClick: handleGrantPlan,
        isDisabled: !hasFullAccess,
      },
      {
        key: "edit",
        label: t("edit"),
        icon: <EditIcon />,
        onClick: handleClickEdit,
        isDisabled: !hasFullAccess,
      },
      {
        key: "remove",
        label: t("remove"),
        icon: <DeleteIcon />,
        onClick: handleClickDelete,
        type: "delete",
        isDisabled: !row.canDeleteAgreement || !hasFullAccess,
      },
    ];

    if (row.statusId !== PlanStatusesBasedOnAPIStatusId?.draft) {
      menuItems = insert(
        2,
        {
          key: "invite",
          label: (
            <>
              <Ui.m>
                {row.invitationDate
                  ? row.statusId === PlanStatusesBasedOnAPIStatusId.waitingForReceiver
                    ? t("resendInviteReceiver")
                    : row.statusId === PlanStatusesBasedOnAPIStatusId.waitingForManager
                    ? t("resendInviteManager")
                    : row.statusId === PlanStatusesBasedOnAPIStatusId.granted
                    ? t("resendInviteReceiver")
                    : t("resendInviteReceiver")
                  : t("sendInvite")}
              </Ui.m>

              <Ui.xs style={{ color: scssVariables.foregroundLow }}>
                {row.statusId === PlanStatusesBasedOnAPIStatusId.waitingForManager
                  ? row.managerEmail
                  : row.stakeholderEmail}
              </Ui.xs>
            </>
          ),
          icon: <EmailActionSend />,
          onClick: handleSendInvite,
          isDisabled: !hasFullAccess,
        },
        menuItems
      );
    }

    if (
      row.statusId === PlanStatusesBasedOnAPIStatusId.active &&
      (row.instrumentTypeId === InstrumentTypesIdsEnum.OPTION || row.instrumentTypeId === InstrumentTypesIdsEnum.RSA)
    ) {
      menuItems.pop();

      menuItems.push({
        key: "terminate",
        label: t("terminate"),
        icon: <RemoveIcon color={scssVariables.critical500} />,
        onClick: handleClickTerminate,
        type: "terminate",
        isDisabled: !hasFullAccess,
      });

      menuItems.push({
        key: "remove",
        label: t("remove"),
        icon: <DeleteIcon />,
        onClick: handleClickDelete,
        type: "delete",
        isDisabled: !row.canDeleteAgreement || !hasFullAccess,
      });
    }

    if (
      row.statusId === PlanStatusesBasedOnAPIStatusId.terminated ||
      row.statusId === PlanStatusesBasedOnAPIStatusId.expired
    ) {
      menuItems.length = 0;

      menuItems.push({
        key: "remove",
        label: t("remove"),
        icon: <DeleteIcon />,
        onClick: handleClickDelete,
        type: "delete",
        noBorder: true,
        isDisabled: !row.canDeleteAgreement || !hasFullAccess,
      });
    }

    if (row.statusId === PlanStatusesBasedOnAPIStatusId.active) {
      menuItems = menuItems.filter((item) => item.key !== "edit" && item.key !== "invite");
    }

    if (row.statusId !== PlanStatusesBasedOnAPIStatusId.draft) {
      menuItems = menuItems.filter((item) => item.key !== "grant" && item.key !== "edit");
    }

    return menuItems;
  }, [
    handleClickDelete,
    handleClickEdit,
    handleClickTerminate,
    handleGrantPlan,
    handleSendInvite,
    hasFullAccess,
    row.canDeleteAgreement,
    row.instrumentTypeId,
    row.invitationDate,
    row.managerEmail,
    row.stakeholderEmail,
    row.statusId,
  ]);

  return (
    <motion.tr className={classes.programTableRow}>
      <TdView>
        <Ui.s bold>{row.representedBy}</Ui.s>

        {and(row.isCompanyOwned, !isNil(row.stakholderCompanyName)) ? (
          <Ui.s className={classes.representedBy}>{row.stakholderCompanyName}</Ui.s>
        ) : row.stakeholderName !== row.representedBy ? (
          <Ui.s className={classes.representedBy}>{row.stakeholderName}</Ui.s>
        ) : null}
      </TdView>

      {isOneOff && (
        <TdView>
          <Ui.xs>{row.instrumentTypeName}</Ui.xs>
        </TdView>
      )}

      {(isSO || isOneOff) && (
        <TdView>
          <Ui.s>{fNumber(row.exercisePrice, "value")}</Ui.s>
        </TdView>
      )}

      <TdView>
        <div className="d-flex justify-content-between align-items-start flex-column">
          <div className="d-flex">
            <Ui.xs color="foregroundLow">
              {fNumber(row.vestedShares, "amount")}/{fNumber(row.restrictedShares, "amount")}
            </Ui.xs>
            {!!row.sharesLostByTermination && (
              <Ui.xs className="ms-1" color="foregroundLow">
                (<s>{fNumber(row.sharesLostByTermination, "amount")}</s>)
              </Ui.xs>
            )}

            {row.statusId === PlanStatusesBasedOnAPIStatusId.expired && (
              <Ui.xs className="ms-1" color="foregroundLow">
                (<s>{fNumber(row.restrictedShares - row.vestedShares, "amount")}</s>)
              </Ui.xs>
            )}
          </div>
          <ProgressBar
            className={
              row.statusId === PlanStatusesBasedOnAPIStatusId.expired
                ? classes.expired
                : row.sharesLostByTermination
                ? classes.progressBarNegative
                : classes.progressBar
            }
            now={Math.ceil((row.vestedShares / row.restrictedShares) * 100)}
            bsPrefix="table-progress-bar"
          />
        </div>
      </TdView>
      {(isSO || isOneOff) && (
        <TdView>
          <Ui.s>
            {row.optionsExpirationDate
              ? fNumber(row.exercisedShares, "amount") + "/" + fNumber(row.vestedShares, "amount")
              : "-"}
          </Ui.s>
        </TdView>
      )}

      <TdView>
        <Ui.s>{transformDateToCommonDateFormat(row.startDate as string)}</Ui.s>
      </TdView>

      <TdView>
        <Ui.s>{row.endTime ? transformDateToCommonDateFormat(row.endTime as string) : "-"}</Ui.s>
      </TdView>

      <TdView>
        <Ui.s>
          {row.optionsExpirationDate ? transformDateToCommonDateFormat(row.optionsExpirationDate as string) : "-"}
        </Ui.s>
      </TdView>

      <TdView>
        <Tag variant={status?.variant}>{status?.label}</Tag>
      </TdView>

      <TdView>
        <div className="d-flex justify-content-center">
          {row.invitationSent && (
            <div className={classes.checkmark}>
              <Tooltip
                className={classes.tooltip}
                popupContent={t("inviteSent", { date: transformDateToCommonDateFormat(row.invitationDate) })}
              >
                <CheckIcon />
              </Tooltip>
            </div>
          )}
          {!row.invitationSent && (
            <div className={classes.email}>
              <Tooltip className={classes.tooltip} popupContent={t("inviteNotSent")}>
                <MultiplyIcon />
              </Tooltip>
            </div>
          )}
        </div>
      </TdView>

      <TdView>
        <div className="d-flex justify-content-center">
          {row.essentialTerms ? (
            <Tooltip
              popupContent={
                <div className="d-flex flex-column align-items-start" style={{ width: "200px" }}>
                  <Ui.xs className="mb-1" bold>
                    {t("termsTooltipTitle")}
                  </Ui.xs>
                  <Ui.xs className="text-start" color="foregroundLow" style={{ whiteSpace: "break-spaces" }}>
                    {row.essentialTerms}
                  </Ui.xs>
                </div>
              }
            >
              <CommonFileIcon fontSize={24} color={scssVariables.foregroundLow} />
            </Tooltip>
          ) : (
            "-"
          )}
        </div>
      </TdView>

      <TdView
        style={{ width: 40 }}
        onClick={(e) => {
          e.stopPropagation();
          document.body.click();
        }}
      >
        <ContextMenu items={menuItems}>
          <Button size="s" isOnlyIcon variant="dark" data-testid="plan-dropdown-btn" className={classes.actionButton}>
            <MenuTabBarVerticalIcon />
          </Button>
        </ContextMenu>
      </TdView>
    </motion.tr>
  );
};

export default memo(ProgramTableRow);
