import { FC, useMemo, useState } from "react";

import CTable from "common/components/atoms/Table/Table";
import { sortingParams } from "common/types/Collapsible.types";
import { createTranslation, TranslationNS } from "translation";

import { Shareholder } from "../types";
import ShareholdersTableBody from "./shareholders-table-body";
import ShareholdersTableHeader from "./shareholders-table-header";
import classes from "./styles.module.scss";
import { HeaderRow, ShareholdersTableKeys, ShareholdersTableSorting, TableType } from "./types";

type PT = {
  type: TableType;
  isLoading: boolean;
  shareholders: Shareholder[];
};

const t = createTranslation(
  TranslationNS.pages,
  "aro.main.steps.shareholdersAtYearStart.body.listOfShareholders.tableColumns"
);

const ShareholdersTable: FC<PT> = ({ type, isLoading, shareholders }) => {
  const [activeSortedValue, setActiveSortedValue] = useState<ShareholdersTableSorting>({
    field: ShareholdersTableKeys.missingInfo,
    sortDirection: sortingParams.inc,
  });

  const rows: HeaderRow[] = useMemo(
    () => [
      {
        key: ShareholdersTableKeys.shareholderName,
        value: t("shareholder"),
        sortDisabled: true,
      },

      ...(type === "end"
        ? [
            {
              key: ShareholdersTableKeys.isin,
              value: t("isin"),
              sortDisabled: true,
            },
          ]
        : []),
      {
        key: ShareholdersTableKeys.shareClass,
        value: t("shareClass"),
        sortDisabled: true,
      },
      {
        key: ShareholdersTableKeys.numberOfShares,
        value: t("numberOfShares"),
        sortDisabled: true,
      },
      ...(type === "end"
        ? [
            {
              key: ShareholdersTableKeys.missingInfo,
              value: t("missingInfo"),
            },
          ]
        : []),
    ],
    [type]
  );

  return (
    <CTable
      className={classes.table}
      headComponent={<ShareholdersTableHeader rows={rows} sort={activeSortedValue} setSort={setActiveSortedValue} />}
      bodyComponent={<ShareholdersTableBody type={type} shareholders={shareholders} sort={activeSortedValue} />}
    />
  );
};

export default ShareholdersTable;
