import axios from "axios";

import {
  GetAroDetailsResponse,
  GetCompanyInformationResponse,
  GetDividendsResponse,
  GetFormTransactionsResponse,
  GetShareholdersAtYearEndResponse,
  GetShareholdersAtYearStartResponse,
  GetTransactionsResponse,
  PostCompanyInformationBody,
  PostCompanyInformationResponse,
  PostShareholdersIsinsBody,
  PostTransactionsBody,
  PostTransactionsDoneBody,
} from "./types";

const apiBase = "api/aro";

export const aroService = {
  getAroDetails: async (companyId: string): Promise<GetAroDetailsResponse | null> => {
    try {
      const response = await axios.get<GetAroDetailsResponse>(`${apiBase}/details/${companyId}`);

      if (response.status === 200) {
        return response.data;
      }

      return null;
    } catch (e) {
      return null;
    }
  },

  getCompanyInformation: async (companyId: string): Promise<GetCompanyInformationResponse | null> => {
    try {
      const response = await axios.get<GetCompanyInformationResponse>(`${apiBase}/company/${companyId}`);

      if (response.status === 200) {
        return response.data;
      }

      return null;
    } catch (e) {
      return null;
    }
  },

  postCompanyInformation: async (body: PostCompanyInformationBody): Promise<PostCompanyInformationResponse | null> => {
    try {
      const response = await axios.post<PostCompanyInformationResponse>(`${apiBase}/company/details`, body);

      if (response.status === 200) {
        return response.data;
      }

      return null;
    } catch (e) {
      return null;
    }
  },

  getShareholdersAtYearStart: async (companyId: string): Promise<GetShareholdersAtYearStartResponse | null> => {
    try {
      const response = await axios.get<GetShareholdersAtYearStartResponse>(
        `${apiBase}/shareholders/last-year/${companyId}`
      );

      if (response.status === 200) {
        return response.data;
      }

      return null;
    } catch (e) {
      return null;
    }
  },

  getTransactions: async (companyId: string): Promise<GetTransactionsResponse | null> => {
    try {
      const response = await axios.get<GetTransactionsResponse>(`${apiBase}/transactions/${companyId}`);

      if (response.status === 200) {
        return response.data;
      }

      return null;
    } catch (e) {
      return null;
    }
  },

  getFormTransactions: async (companyId: string): Promise<GetFormTransactionsResponse | null> => {
    try {
      const response = await axios.get<GetFormTransactionsResponse>(`${apiBase}/transactions/all/${companyId}`);

      if (response.status === 200) {
        return response.data;
      }

      return null;
    } catch (e) {
      return null;
    }
  },

  postTransactions: async (body: PostTransactionsBody): Promise<boolean> => {
    try {
      const response = await axios.post(`${apiBase}/transactions`, body);

      if (response.status === 200) {
        return true;
      }

      return false;
    } catch (e) {
      return false;
    }
  },

  postTransactionsDone: async (body: PostTransactionsDoneBody): Promise<boolean> => {
    try {
      const response = await axios.post(`${apiBase}/transactions/done`, body);

      if (response.status === 200) {
        return true;
      }

      return false;
    } catch (e) {
      return false;
    }
  },

  getShareholdersAtYearEnd: async (companyId: string): Promise<GetShareholdersAtYearEndResponse | null> => {
    try {
      const response = await axios.get<GetShareholdersAtYearEndResponse>(
        `${apiBase}/shareholders/current/${companyId}`
      );

      if (response.status === 200) {
        return response.data;
      }

      return null;
    } catch (e) {
      return null;
    }
  },

  postShareholdersIsins: async (body: PostShareholdersIsinsBody): Promise<boolean> => {
    try {
      const response = await axios.post(`${apiBase}/stakeholders`, body);

      if (response.status === 200) {
        return true;
      }

      return false;
    } catch (e) {
      return false;
    }
  },

  getDividends: async (companyId: string): Promise<GetDividendsResponse | null> => {
    try {
      const response = await axios.get<GetDividendsResponse>(`${apiBase}/TODO/${companyId}`);

      if (response.status === 200) {
        return response.data;
      }

      return null;
    } catch (e) {
      return null;
    }
  },

  postFinishAro: async (): Promise<boolean> => {
    try {
      const response = await axios.post(`${apiBase}/TODO`);

      if (response.status === 200) {
        return true;
      }

      return false;
    } catch (e) {
      return false;
    }
  },
};
