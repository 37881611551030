import { FC, memo, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Formik, FormikConfig } from "formik";

import { Button, LoaderContainer, Ui } from "common/components/atoms";
import Skeleton from "common/components/atoms/skeleton/skeleton";
import { ArrowLeftIcon } from "common/icons/svg";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import WizardLayout from "common/layout/WizardLayout/WizardLayout";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { PostFormTransaction } from "../../types";
import classes from "./styles.module.scss";
import Table from "./table";
import useEditTransactionsColumns from "./use-edit-transactions-columns";
import useEditTransactionsForm, { initValues } from "./use-edit-transactions-form";

const t = createTranslation(TranslationNS.pages, "aro.main.steps.transactions.body.editTransactionsTable");

type PT = {
  onClose(): void;
};

const EditTransactionsTable: FC<PT> = memo(({ onClose }) => {
  const { companyId } = useParams<{ companyId?: string }>();

  const year = useStoreState((state) => state.aroModel?.aroDetails?.year || 2000);
  const transactions = useStoreState((state) => state.aroModel?.formTransactions?.transactions);
  const transactionCategories = useStoreState((state) => state.aroModel?.formTransactions?.transactionCategories);
  const issueSharesTypes = useStoreState((state) => state.aroModel?.formTransactions?.issueShareTypes);
  const buySellTypes = useStoreState((state) => state.aroModel?.formTransactions?.buySellTypes);
  const shareClasses = useStoreState((state) => state.aroModel?.formTransactions?.shareClasses);
  const stakeholders = useStoreState((state) => state.aroModel?.formTransactions?.stakeholders);
  const isLoading = useStoreState((state) => state.aroModel?.isFormTransactionsLoading);
  const getFormTransactions = useStoreActions((actions) => actions.aroModel.getFormTransactionsThunk);
  const postTransactions = useStoreActions((actions) => actions.aroModel.postTransactionsThunk);
  const getAroDetails = useStoreActions((actions) => actions.aroModel.getAroDetailsThunk);

  useEffect(() => {
    if (companyId) {
      getFormTransactions(companyId);
    }
  }, [companyId, getFormTransactions]);

  const refetchData = useCallback(() => {
    return getFormTransactions(companyId || "0");
  }, [companyId, getFormTransactions]);

  const { validationSchema, initialValues } = useEditTransactionsForm(
    transactions || [],
    transactionCategories,
    issueSharesTypes,
    buySellTypes
  );

  const { columns } = useEditTransactionsColumns(
    refetchData,
    shareClasses,
    transactionCategories,
    issueSharesTypes,
    buySellTypes,
    stakeholders,
    1 // NOK
  );

  const handleSubmit = useCallback<FormikConfig<PostFormTransaction[]>["onSubmit"]>(
    async (values) => {
      try {
        if (companyId) {
          const existedIds = values
            .filter((transaction) => transaction.transactionId)
            .map((transaction) => transaction.transactionId);

          const transactionIdsToDelete = transactions
            ?.filter((transaction) => !existedIds.includes(transaction.transactionId))
            .map((transaction) => transaction.transactionId) as number[];

          const result = await postTransactions({
            companyId: companyId,
            transactions: values.map((transaction) => ({
              ...transaction,
              numberOfShares: Number(transaction.numberOfShares),
              sharePrice: Number(transaction.sharePrice),
              fromStakeholderId: transaction.fromStakeholderId === 1 ? null : transaction.fromStakeholderId,
            })),
            transactionIdsToDelete: transactionIdsToDelete,
          });

          if (result) {
            notify("success", true, "success");
            getAroDetails(companyId);
            onClose();
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    [companyId, getAroDetails, onClose, postTransactions, transactions]
  );

  return (
    <WizardLayout className={classes.wizard}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ submitForm, isSubmitting }) => (
          <>
            <WizardLayout.Header title={t("title")} />
            <div className="d-flex justify-content-between align-items-center px-7">
              <Button onClick={onClose} isOnlyIcon variant="secondary" isDisabled={isSubmitting}>
                <ArrowLeftIcon />
              </Button>
              <Ui.l color="foregroundMedium">
                {t.el("paragraph", {
                  components: [<Skeleton isLoading={isLoading} key={0} />],
                  values: {
                    dateFrom: `01.01.${year}`,
                    dateTo: `31.12.${year}`,
                  },
                })}
              </Ui.l>
              <Button onClick={submitForm} isLoading={isSubmitting}>
                {t("submitBtn")}
              </Button>
            </div>
            <WizardContent hideSteps className={classes.maxWidth}>
              <div className={classes.wrap}>
                <LoaderContainer loading={isLoading}>
                  <Table columns={columns} initialItems={initValues} />
                </LoaderContainer>
              </div>
            </WizardContent>
          </>
        )}
      </Formik>
    </WizardLayout>
  );
});

export default EditTransactionsTable;
