import { FC, memo } from "react";

import { NewAvatar, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers/useFormatNumbers";
import { CheckIcon, WarningExclamationMarkIcon } from "common/icons/svg";
import { sortingParams } from "common/types/Collapsible.types";
import { scssVariables } from "common/utils/constants";

import { Shareholder } from "../types";
import { ShareholdersTableKeys, ShareholdersTableSorting, TableType } from "./types";

type PT = {
  type: TableType;
  sort: ShareholdersTableSorting;
  shareholders: Shareholder[];
};

const ShareholdersTableBody: FC<PT> = memo(({ sort, type, shareholders }) => {
  const fNumber = useFormatNumbers();

  const sortedShareholders = shareholders.sort((a, b) => {
    if (sort.field === ShareholdersTableKeys.missingInfo) {
      if (sort.sortDirection === sortingParams.inc) {
        return a.missingInformation > b.missingInformation ? 1 : -1;
      }

      return a.missingInformation > b.missingInformation ? -1 : 1;
    }

    return 1;
  });

  return (
    <tbody>
      {sortedShareholders.map((shareholder) => (
        <tr key={shareholder.stakeholderId}>
          <td>
            <div className="d-flex align-items-center">
              <NewAvatar
                imageUrl={shareholder.avatarFilePath}
                company={shareholder.isCompanyOwned}
                initials={shareholder.initials}
                size="s"
              />

              <div className="ms-2">
                <div className="d-flex align-items-center">
                  <Ui.s bold className="me-1">
                    {shareholder.shareholderName || "-"}
                  </Ui.s>
                </div>

                {shareholder.isCompanyOwned ? (
                  <Ui.s style={{ color: scssVariables.foregroundLow }}>{shareholder.representedBy}</Ui.s>
                ) : null}
              </div>
            </div>
          </td>
          {type === "end" ? (
            <td>
              <Ui.s>{shareholder.isin || "-"}</Ui.s>
            </td>
          ) : null}
          <td>
            <Ui.s>{shareholder.shareClassName}</Ui.s>
          </td>
          <td className="text-end">
            <Ui.s>{fNumber(shareholder.numberOfShares, "amount")}</Ui.s>
          </td>
          {type === "end" ? (
            <td>
              <div className="d-flex justify-content-center">
                {shareholder.missingInformation ? (
                  <WarningExclamationMarkIcon fontSize={24} color={scssVariables.information700} />
                ) : (
                  <CheckIcon fontSize={24} color={scssVariables.positive500} />
                )}
              </div>
            </td>
          ) : null}
        </tr>
      ))}
    </tbody>
  );
});

export default ShareholdersTableBody;
