import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useStoreActions, useStoreState } from "store/store";

import { getPath } from "../Router/RouterHelper";
import { ROUTER_V2 } from "../Router/RouterV2.types";

const useOnboardingMiddleware = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthenticated = useStoreState((state) => state.authentication.isAuthenticated);
  const aroOnboarding = useStoreState((state) => state.companyOnboardingModel.aroOnboarding);
  const { setRedirectUrl } = useStoreActions((actions) => actions.app);
  const { redirectUrl } = useStoreState((state) => state.app);
  const userStore = useStoreState((state) => state.account.user);
  const { setAroOnboarding } = useStoreActions((actions) => actions.companyOnboardingModel);

  useEffect(() => {
    if (location.pathname.includes("/invitation/")) {
      setRedirectUrl(location.pathname);
    }
  }, [location.pathname, setRedirectUrl]);

  useEffect(() => {
    if (redirectUrl && redirectUrl !== location.pathname && isAuthenticated) {
      navigate(redirectUrl);
    } else if (!redirectUrl && userStore && !userStore.isOnboarded && location.pathname != "/unimicro-landing") {
      navigate(getPath(["onboard", "user"]));
    }

    if (location.pathname.includes(getPath(["aro", "onboard"]))) {
      setAroOnboarding({
        isActive: true,
      });
    }
  }, [isAuthenticated, location.pathname, navigate, redirectUrl, setAroOnboarding, setRedirectUrl, userStore]);

  useEffect(() => {
    if (aroOnboarding.isActive && isAuthenticated && !redirectUrl && userStore && userStore.isOnboarded) {
      navigate(getPath(["aro", "onboard"]));
    }
  }, [aroOnboarding.isActive, isAuthenticated, navigate, redirectUrl, userStore]);
};

export default useOnboardingMiddleware;
